import { createApp } from 'vue'

import ChargerOwnSlow from '@/assets/map/markers/charger-own-slow.svg?component'
import ChargerOwnFast from '@/assets/map/markers/charger-own-fast.svg?component'
import ChargerOwnRapid from '@/assets/map/markers/charger-own-rapid.svg?component'
import ChargerOwnUltraRapid from '@/assets/map/markers/charger-own-ultra_rapid.svg?component'

import ChargerEconnectSlow from '@/assets/map/markers/charger-eConnect-slow.svg?component'
import ChargerEConnectFast from '@/assets/map/markers/charger-eConnect-fast.svg?component'
import ChargerEConnectRapid from '@/assets/map/markers/charger-eConnect-rapid.svg?component'
import ChargerEConnectUltraRapid from '@/assets/map/markers/charger-eConnect-ultra-rapid.svg?component'

import ChargerCompetitionSlow from '@/assets/map/markers/charger-competition-slow.svg?component'
import ChargerCompetitionFast from '@/assets/map/markers/charger-competition-fast.svg?component'
import ChargerCompetitionRapid from '@/assets/map/markers/charger-competition-rapid.svg?component'
import ChargerCompetitionUltraRapid from '@/assets/map/markers/charger-competition-ultra_rapid.svg?component'

import sanitizeHtml from 'sanitize-html'

enum SharedComponentTypes {
  ChargerOwnSlow = 'ChargerOwnSlow',
  ChargerOwnFast = 'ChargerOwnFast',
  ChargerOwnRapid = 'ChargerOwnRapid',
  ChargerOwnUltraRapid = 'ChargerOwnUltraRapid',
  ChargerCompetitionSlow = 'ChargerCompetitionSlow',
  ChargerCompetitionFast = 'ChargerCompetitionFast',
  ChargerCompetitionRapid = 'ChargerCompetitionRapid',
  ChargerCompetitionUltraRapid = 'ChargerCompetitionUltraRapid',
  ChargerEconnectSlow = 'ChargerEconnectSlow',
  ChargerEconnectFast = 'ChargerEconnectFast',
  ChargerEconnectRapid = 'ChargerEconnectRapid',
  ChargerEConnectUltraRapid = 'ChargerEconnectUltraRapid',
}

const sharedComponents = [
  { name: SharedComponentTypes.ChargerOwnSlow, component: ChargerOwnSlow },
  { name: SharedComponentTypes.ChargerOwnFast, component: ChargerOwnFast },
  { name: SharedComponentTypes.ChargerOwnRapid, component: ChargerOwnRapid },
  { name: SharedComponentTypes.ChargerOwnUltraRapid, component: ChargerOwnUltraRapid },

  { name: SharedComponentTypes.ChargerEconnectSlow, component: ChargerEconnectSlow },
  { name: SharedComponentTypes.ChargerEconnectFast, component: ChargerEConnectFast },
  { name: SharedComponentTypes.ChargerEconnectRapid, component: ChargerEConnectRapid },
  { name: SharedComponentTypes.ChargerEConnectUltraRapid, component: ChargerEConnectUltraRapid },

  { name: SharedComponentTypes.ChargerCompetitionSlow, component: ChargerCompetitionSlow },
  { name: SharedComponentTypes.ChargerCompetitionFast, component: ChargerCompetitionFast },
  { name: SharedComponentTypes.ChargerCompetitionRapid, component: ChargerCompetitionRapid },
  { name: SharedComponentTypes.ChargerCompetitionUltraRapid, component: ChargerCompetitionUltraRapid },
] as const

const sharedDictionary = sharedComponents.reduce((acc, item) => {
  const root = document.createElement('div')
  const renderedComponent = createApp({
    ...item.component,
  } as any)

  const template = renderedComponent.mount(root).$el.innerHTML

  renderedComponent.unmount()

  acc[item.name] = template

  return acc
}, {} as Record<SharedComponentTypes, string>)

export default sharedDictionary


export const generateMarker = (color: string, label: string) => {
  const svg = `
    <svg
      width="32"
      height="32"
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle
        cx="16"
        cy="17"
        r="14"
        fill="${sanitizeHtml(color)}"
        stroke="white"
        stroke-width="3"
      />
      <text
        x="50%"
        y="55%"
        font-size="14px"
        font-weight="500"
        dominant-baseline="middle"
        text-anchor="middle"
        fill="white"
        font-family="Roboto, sans-serif"
      >${sanitizeHtml(label)}</text>
    </svg>
  `
  // Now we can use btoa to convert the svg to base64
  const base64 = btoa(svg)
  return `data:image/svg+xml;base64,${base64}`
}
// used for cashing the response for reuse in the map feature
const genericMarkerUrlDictionary = (): (c: string, l: string) => string => {
  const markerCache = new Map<string, string>()
 
  return (color: string, label: string): string => {
    const markerKey = `${color}-${label}`
    if (markerCache.has(markerKey)) {
      return markerCache.get(markerKey) as string
    } else {
      const url = generateMarker(color, label)
      markerCache.set(markerKey, url)
      return url 
    }
  }
} 

export const customUploadMarkerIcon = genericMarkerUrlDictionary()
