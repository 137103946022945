import MapLayers from '@/libs/MapLayers/MapLayers'

export default function (mapLayers: MapLayers) {
  return function (app: any): void {
    app.config.globalProperties.$MapLayers = mapLayers
  }
}

declare module '@vue/runtime-core' {
  interface ComponentCustomProperties {
    $MapLayers: MapLayers
  }
}
