import {ColoringFunctions} from '@/utils/layers/layout-config'
import {Feature, GeoJsonProperties, Geometry} from 'geojson'

export const mapOverlayZIndex = {
  mapCoverageStyle: 5003,
  mapWorkspaceShadow: 5002,
  mapWorkspaceRegionPolygon: 5002,
  mapWorkspaceWards: 5001,
  mapWorkspaceLocalFactory: 5004,
  mapWorkspaceFreehold: 5005,
  mapWorkspaceLeasehold: 5006,
  mapLayer: 5100,
  mapLayerHover: 5101,
  mapLayerSelected: 5102,
  mapCatchmentLayer: 5103,
  mapLayerCatchmentHover: 5104,
  tooltips: 5100,
  customOverlays: 5101,
}

function getOffstreetColorWithAlpha(propertyValue: string | undefined, alpha: number): [number, number, number, number] {
  if (propertyValue === 'high_probability') {
    return [250, 0, 0, alpha]
  } else if (propertyValue === 'low_probability') {
    return [0, 255, 0, alpha]
  } else if (propertyValue === 'non_residential' || propertyValue === 'mainly_non_residential_area') {
    return [131, 173, 191, alpha]
  } else if (propertyValue === 'mixed') {
    return [252, 231, 125, alpha]
  } else {
    return [0, 0, 0, 0]
  }
}

export const offstreetParkingColoring = new ColoringFunctions(
  (feature: Feature<Geometry, GeoJsonProperties>): [number, number, number, number] => {
    return getOffstreetColorWithAlpha(feature.properties?.offp_cat, 80)
  },
  (feature: Feature<Geometry, GeoJsonProperties>): [number, number, number, number] => {
    return getOffstreetColorWithAlpha(feature.properties?.offp_cat, 230)
  },
)


export const offstreetParkingColoringResidential = new ColoringFunctions(
  (feature: Feature<Geometry, GeoJsonProperties>): [number, number, number, number] => {
    return getOffstreetColorWithAlpha(feature.properties?.name, 80)
  },
  (feature: Feature<Geometry, GeoJsonProperties>): [number, number, number, number] => {
    return getOffstreetColorWithAlpha(feature.properties?.name, 230)
  },
)

const getColorForClusterV1 = (propertyValue: string, alpha: number): [number, number, number, number] => {
  if (propertyValue === 'mixed; shop oriented, food&drink, everyday') {
    return [175, 3, 151, alpha]
  } else if (propertyValue === 'culture, historic, attraction') {
    return [255, 215, 0, alpha]
  } else if (propertyValue === 'health') {
    return [50, 205, 50, alpha]
  } else if (propertyValue === 'mixed: food&drink, nightlife, community oriented, everyday') {
    return [255, 105, 180, alpha]
  } else if (propertyValue === 'sport') {
    return [255, 69, 0, alpha]
  } else if (propertyValue === 'accomodation: nature, adventure') {
    return [34, 139, 34, alpha]
  } else if (propertyValue === 'mixed: significant parking, outdoor, family friendly, not so shop oriented') {
    return [147, 112, 219, alpha]
  } else if (propertyValue === 'attractions') {
    return [255, 140, 0, alpha]
  } else if (propertyValue === 'accomodation: hotels, resorts') {
    return [32, 178, 170, alpha]
  } else if (propertyValue === 'education') {
    return [30, 144, 255, alpha]
  } else {
    return [255, 255, 255, alpha]
  }
}

export const clusteringV1Coloring = new ColoringFunctions(
  (feature: Feature<Geometry, GeoJsonProperties>): [number, number, number, number] => {
    const propertyValue = feature.properties?.cluster || ''
    return getColorForClusterV1(propertyValue, 80)
  },
  (feature: Feature<Geometry, GeoJsonProperties>): [number, number, number, number] => {
    const propertyValue = feature.properties?.cluster || ''
    return getColorForClusterV1(propertyValue, 230)
  },
)


const getColorForV2Cluster = (propertyValue: string, alpha: number): [number, number, number, number] => {
  if (propertyValue === 'culture, historic, attraction') {
    return [255, 215, 0, alpha]
  } else if (propertyValue === 'health') {
    return [50, 205, 50, alpha]
  } else if (propertyValue === 'sport') {
    return [255, 69, 0, alpha]
  } else if (propertyValue === 'accommodation') {
    return [175, 3, 151, alpha]
  } else if (propertyValue === 'mixed: not so shop oriented, with parking, nightlife, accommodation') {
    return [147, 112, 219, alpha]
  } else if (propertyValue === 'attraction') {
    return [255, 140, 0, alpha]
  } else if (propertyValue === 'mixed: shops, retail, town centres') {
    return [32, 178, 170, alpha]
  } else if (propertyValue === 'mixed: residential, small shops and amenities') {
    return [34, 139, 34, alpha]
  } else if (propertyValue === 'education') {
    return [30, 144, 255, alpha]
  } else {
    return [0, 0, 0, alpha]
  }
}


const getColorForV3Cluster = (propertyValue: string, alpha: number): [number, number, number, number] => {
  if (propertyValue === 'culture') {
    return [255, 215, 0, alpha]
  } else if (propertyValue === 'health') {
    return [50, 205, 50, alpha]
  } else if (propertyValue === 'sport') {
    return [255, 69, 0, alpha]
  } else if (propertyValue === 'accommodation') {
    return [175, 3, 151, alpha]
  } else if (propertyValue === 'leisure') {
    return [147, 112, 219, alpha]
  } else if (propertyValue === 'fun') {
    return [255, 140, 0, alpha]
  } else if (propertyValue === 'supermarket_with_services') {
    return [32, 178, 170, alpha]
  } else if (propertyValue === 'shops_and_amenities') {
    return [34, 139, 34, alpha]
  } else if (propertyValue === 'education') {
    return [30, 144, 255, alpha]
  } else if (propertyValue === 'high_education') {
    return [87, 31, 255, alpha]
  } else if (propertyValue === 'transport_node') {
    return [67, 230, 210, alpha]
  } else if (propertyValue === 'service_station') {
    return [67, 230, 113, alpha]
  } else {
    return [0, 0, 0, alpha]
  }
}

export const clusteringV2Coloring = new ColoringFunctions(
  (feature: Feature<Geometry, GeoJsonProperties>): [number, number, number, number] => {
    const propertyValue = feature.properties?.cluster || ''
    return getColorForV2Cluster(propertyValue, 80)
  },
  (feature: Feature<Geometry, GeoJsonProperties>): [number, number, number, number] => {
    const propertyValue = feature.properties?.cluster || ''
    return getColorForV2Cluster(propertyValue, 230)
  },
)


export const clusteringV3Coloring = new ColoringFunctions(
  (feature: Feature<Geometry, GeoJsonProperties>): [number, number, number, number] => {
    const propertyValue = feature.properties?.category_agg || ''
    return getColorForV3Cluster(propertyValue, 80)
  },
  (feature: Feature<Geometry, GeoJsonProperties>): [number, number, number, number] => {
    const propertyValue = feature.properties?.category_agg || ''
    return getColorForV3Cluster(propertyValue, 230)
  },
)


export const floodZoneUkFunction = (feature: Feature<Geometry, GeoJsonProperties>): [number, number, number, number] => {
  const layerUk = feature.properties?.layer
  const layerDe = feature.properties?.probability
  if (layerUk === 'Flood Zone 2' || layerDe === 'medium') {
    return [164, 189, 222, 100]
  } else if (layerUk === 'Flood Zone 3' || layerDe === 'high') {
    return [118, 161, 210, 150]
  } else {
    return [0,0,0,255]
  }
}

export const floodZoneUsFunction = (feature: Feature<Geometry, GeoJsonProperties>): [number, number, number, number] => {
  const layer = feature.properties?.probability
  if (layer === 'low') {
    return [164, 189, 222, 100]
  } else if (layer === 'high') {
    return [118, 161, 210, 150]
  } else {
    return [0,0,0,255]
  }
}
