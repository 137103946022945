import {
  isMetric,
  METERS_TO_FEET,
  METERS_TO_MILES,
  SQ_METERS_TO_SQ_FEET,
  SQ_METERS_TO_SQ_MILES,
} from '@/libs/distance'
import {i18n} from '@/plugins/i18n'

export function pretty(ugly: string | null | undefined, keepCaps = false): string {
  if (!ugly) {
    return ''
  }

  ugly = ugly.replaceAll(/[_\s]+/g, ' ')

  if (keepCaps) {
    return ugly.substring(0, 1).toLocaleUpperCase() + ugly.substring(1)
  }

  return (
    ugly.substring(0, 1).toLocaleUpperCase() +
    ugly.substring(1).toLocaleLowerCase()
  )
}

export function prettySentence(ugly: string | null | undefined): string {
  if (!ugly) {
    return ''
  }

  // Patch for US charger
  if (ugly === 'level_3_dc_fast') {
    return 'Level 3 / DC Fast'
  }

  return ugly.split('_').map(u => pretty(u)).join(' ') 
}

export function prettyCaps(ugly: string | null | undefined): string {
  if (!ugly) {
    return ''
  }

  ugly = ('' + ugly).replaceAll(/[_\s]+/g, ' ')
  return ugly.toLocaleUpperCase()
}

export function truncateText(text: string, length: number): string {
  if (!text) {
    return ''
  }

  if (text.length > length) {
    return text.substring(0, length - 3) + '…'
  } else {
    return text
  }
}

export function formatDistance(distance: number): string {
  const { n } = i18n.global

  if (distance < 1000) {
    return `${n(Math.ceil(distance), 'decimal', { maximumFractionDigits: 1 })} m`
  } else {
    return `${n(distance / 1000, 'decimal', { maximumFractionDigits: 1 })} km`
  }
}

export function localeFormatDistance(distance: number): string {
  // Convert meters to feet if needed
  if (isMetric()) {
    return formatDistance(distance)
  }

  return formatDistanceInFeetAndMiles(distance)
}

export function formatArea(area: number): string {
  const { n } = i18n.global

  if (area < 1000000) {
    return `${n(Math.ceil(area), 'decimal', { maximumFractionDigits: 1 })} m²`
  } else {
    return `${n(area / 1000000, 'decimal', { maximumFractionDigits: 1 })} km²`
  }
}

export function formatDistanceInFeetAndMiles(distance: number): string {
  const { n } = i18n.global

  if (distance < 1609.34) { // Less than a mile in meters
    return `${n(Math.ceil(distance * METERS_TO_FEET), 'decimal', { maximumFractionDigits: 1 })} ft`
  } else {
    return `${n(distance * METERS_TO_MILES, 'decimal', { maximumFractionDigits: 1 })} mi`
  }
}

export function formatAreaInSqFeetAndSqMiles(area: number): string {
  const { n } = i18n.global

  if (area < 2589988.11) { // Less than a square mile in square meters
    return `${n(Math.ceil(area * SQ_METERS_TO_SQ_FEET), 'decimal', { maximumFractionDigits: 1 })} ft²`
  } else {
    return `${n(area * SQ_METERS_TO_SQ_MILES, 'decimal', { maximumFractionDigits: 1 })} mi²`
  }
}

export function roundedDecimal(value: number, maximumFractionDigits = 2) {
  const { n } = i18n.global
  return `${n(value, 'decimal', { maximumFractionDigits: maximumFractionDigits })}`
}

export function formatByPercentage(value: number, maximumFractionDigits = 2) {
  const { n } = i18n.global
  return `${n(value * 100, 'decimal', { maximumFractionDigits: maximumFractionDigits })}%`
}

export function cleanString(original: string): string {
  return original.replaceAll(/_+/g, ' ').toLowerCase()
}

export function cleanFileName(original: string | undefined): string {
  if (!original) return ''
  return original
    .trim() // Trim leading/trailing whitespace
    .replace(/[^\p{L}0-9_.-]/gu, '-') // Replace non-allowed characters with hyphens
    .replace(/-+/g, '-') // Collapse consecutive hyphens
    .replace(/^-+/, '') // Remove leading hyphens
    .replace(/-+$/, '') // Remove trailing hyphens
    .toLowerCase() // Convert to lowercase
}
