import {createApp} from 'vue'
import {createPinia} from 'pinia'
import VueKeycloak from '@dsb-norge/vue-keycloak-js'
import {createRouter, createWebHistory} from 'vue-router'
import DodonaApiPlugin from '@/plugins/API'
import axios, {AxiosRequestConfig} from 'axios'
import VueNumberInput from '@chenfengyuan/vue-number-input'
import ActivityLoggerPlugin from '@/plugins/ActivityLogger'
import ActivityLogger from '@/libs/ActivityLogger'
import {BootstrapVue, BootstrapVueIcons} from 'bootstrap-vue'
import GlobalUtils from '@/utils/global.utils'
import Hotjar from 'vue-hotjar'
import MapLayersPlugin from './plugins/MapLayers'
import Root from './views/Root.vue'
import {VueKeycloakOptions} from '@dsb-norge/vue-keycloak-js/dist/types'
import {getRoutes} from '@/views/routes'
import {i18n} from '@/plugins/i18n'
import withUUID from 'vue-uuid'
import {Keycloak} from '@/libs/auth/types'
import DodonaBackend from '@/libs/api/api-client'
import DodonaToastPlugin from '@/plugins/DodonaToast'
import PrimeVue from 'primevue/config'
import * as Sentry from '@sentry/vue'
import vueprimeConfig from './primevue-config'
import UserRoles from '@/plugins/UserRoles'
import {RoleManager} from '@/libs/RoleManager'
import VueKonva from 'vue-konva'
import Tooltip from 'primevue/tooltip'
import DodonaToast from '@/libs/DodonaToast'
import AnnounceKit from 'announcekit-vue'
import MapLayers from '@/libs/MapLayers/MapLayers'
import {useUserInterfaceStore} from '@/stores/user-interface.store'
import {useWorkspaceStore} from '@/stores/workspace.store'
import {debounce} from 'lodash'
import VueVirtualScroller from 'vue-virtual-scroller'
import ConfirmationService from 'primevue/confirmationservice'

// --- CSS ---
// this is separated and in css because of performance issues on development
import '@/styles/index.css'
import '@/styles/bootstrap.scss'
import '@/styles/custom.scss'
import 'primeicons/primeicons.css'
import 'vue-virtual-scroller/dist/vue-virtual-scroller.css'
// --- CSS --- 

GlobalUtils.appInfo()

console.log(import.meta.env.VITE_APP_DODONA_API_BASE_URL)

/**
 * Axios HTTP client with baseURL & access-token (if available) for Dodona Backend API
 */
const httpClient = axios.create({
  baseURL: import.meta.env.VITE_APP_DODONA_API_BASE_URL,
  withCredentials: true,
})

const app = createApp({
  name: 'DodonaAnalytics',
  created(): void {
    const addRequestBreadcrumb = (req: AxiosRequestConfig) => {
      try {
        const url = (req.baseURL ?? '') + (req.url ?? '/')
        const method = req.method?.toUpperCase()
        Sentry.addBreadcrumb({
          category: 'API request',
          message: method + ' ' + url,
          level: 'info',
          data: {
            url,
            method,
            body: req.data,
          },
        })
      } catch (e) {
        console.warn('Error while adding request breadcrumb', e)
      }

      return req
    }

    this.$API.client.interceptors.request.use(
      addRequestBreadcrumb,
      addRequestBreadcrumb,
    )

    // catch 403 errors from the API, remove (invalid credentials)
    this.$API.client.interceptors.response.use(
      (res: any) => res,
      async (error: any) => {
        if (axios.isAxiosError(error)) {
          try {
            const { response, responseURL, status } = error.request
            Sentry.addBreadcrumb({
              category: 'API response error',
              message: `[${status}] error for ${responseURL}`,
              level: 'error',
              data: {
                response: JSON.stringify({
                  response,
                  responseURL,
                  status,
                }, null, 2),
              },
            })
          } catch (e) {
            console.warn('error adding error breadcrumb', e)
          }

          switch (true) {
            case error.request.status === 401:
              console.warn('API returned 401, logging out and redirecting to login page')

              // logout, redirects to keycloak login
              await this.$keycloak.keycloak?.logout()
              break

            case error.request.status >= 500 || error.request.status === 0:
              // and issue a new one
              this.$Toast.toast('Something went wrong. Please try again. If the issue persists, contact our support team. Thanks!', {
                title: 'Something went wrong',
                appendToast: true,
                variant: 'danger',
                toaster: 'b-toaster-bottom-right',
              })
              break
          }

          Sentry.captureException(error)
        }

        throw error
      },
    )
  },
  render: (h: any) => {
    return h(Root)
  },
})

if (GlobalUtils.sentryEnabled()) {
  Sentry.init({
    app,
    release: 'mf-frontend@' + MF_APP_VERSION,
    environment: import.meta.env.MODE,
    dsn: 'https://41249209de8fec84f1eae2cf1ca3283d@o4507129323126784.ingest.de.sentry.io/4507214055014480',
    integrations: [
      Sentry.browserTracingIntegration(),
      Sentry.replayIntegration({
        maskAllText: false,
        blockAllMedia: false,
      }),
      Sentry.replayCanvasIntegration(),
      Sentry.breadcrumbsIntegration({
        console: false,
      }),
    ],
    normalizeDepth: 5,
    // Performance Monitoring
    tracesSampleRate: 1.0, //  Capture 100% of the transactions
    // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
    tracePropagationTargets: ['localhost', /https:\/\/.*(backend-(emobility|reporting|dataset)).*/],
    // Session Replay
    replaysSessionSampleRate: 0, // This sets the sample rate at 0%. You may want to change it to 100% while in development and then sample at a lower rate in production.
    replaysOnErrorSampleRate: GlobalUtils.isProduction() ? 1.0 : 0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
    sendDefaultPii: true, // Enables sending IP addresses and other PII
  })

  const getScreenSize = () => ({
    screen: {
      width: window.screen.width,
      height: window.screen.height,
    },
    page: {
      width: window.innerWidth,
      height: window.innerHeight,
    },
  })

  Sentry.setContext('Screen size', getScreenSize())

  window.addEventListener('resize', debounce(() => {
    Sentry.setContext('Screen size', getScreenSize())
  }, 500))

  console.info('Sentry initialized')
}

const backendService = new DodonaBackend(httpClient)
const userRoles = new RoleManager()
const toast = new DodonaToast()
const mapLayers = new MapLayers()

app.use(DodonaApiPlugin(backendService))
app.use(DodonaToastPlugin(toast))
app.use(MapLayersPlugin(mapLayers))
app.use(withUUID)
app.use(VueVirtualScroller)
app.use(UserRoles(userRoles))
app.use(VueKonva)
app.use(ConfirmationService)

app.use(BootstrapVue, {
  BToast: {
    autoHideDelay: 7500,
    zIndex: 10001,
    toaster: 'b-toaster-bottom-right',
  },
  BTooltip: {
    delay: { show: 500, hide: 0 },
  },
  BPopover: {
    delay: 0,
  },
})


app.use(PrimeVue, vueprimeConfig)
app.use(AnnounceKit)

// Vue prime component
app.directive('tooltip', Tooltip)


app.use(BootstrapVueIcons)

const globalModal = () => (app: any, options: any) => {
  // configure the app
  app.config.globalProperties.$modal = {
    show(): Promise<any> {
      return Promise.resolve(options)
    },

    hide(): Promise<any> {
      return Promise.resolve(options)
    },
  }
}

app.use(i18n)
app.use(globalModal)

if (import.meta.env.VITE_APP_HOTJAR_ID) {
  app.use(Hotjar, {
    id: import.meta.env.VITE_APP_HOTJAR_ID,
    isProduction: GlobalUtils.isProduction(),
  })
}

app.config.warnHandler = () => {
  // `trace` is the component hierarchy trace
}

// Global Components
app.component(VueNumberInput.name as string, VueNumberInput)

// Keycloak doesn't allow one client to have multiple subdomains
// We must change the client programmatically if the subdomain changes
const clientId = import.meta.env.VITE_KEYCLOAK_CLIENT_ID + (window.location.href.includes('ck.dodonaanalytics.com') ? '-ck' : '')

const keycloakOptions: VueKeycloakOptions = {
  config: {
    clientId,
    url: import.meta.env.VITE_KEYCLOAK_URL,
    realm: import.meta.env.VITE_KEYCLOAK_REALM,
  },
  init: {
    checkLoginIframe: false,
    onLoad: 'check-sso',
  },
  onReady: async (keycloak) => {
    const router = createRouter({
      history: createWebHistory(),
      routes: getRoutes(),
    })

    router.beforeEach((to, from, next) => {
      if (!to.meta.disableAuth && !keycloak.authenticated) {
        return keycloak.login()
      }

      return next()
    })

    app.use(router)

    const pinia = createPinia()
    app.use(pinia)
    pinia.use(() => {
      return {
        keycloak: keycloak as unknown as Keycloak,
        api: backendService,
        roles: userRoles,
      }
    })

    const ws = useWorkspaceStore()
    const uis = useUserInterfaceStore()
    const activityLogger = new ActivityLogger(mapLayers, router, ws, uis)
    app.use(ActivityLoggerPlugin(activityLogger))

    // Mount the app once keycloak is ready
    app.mount('#app')
  },
}
app.use(VueKeycloak, keycloakOptions)

