import { ChargerStation, SiteCharger } from '@/models/admin/Configuration.model'
import {ChargerSpeed, ChargerSpeedMapping, ChargerUKSpeed, ozevChargerSpeeds} from '@/types/app'
import { RegionUtils } from '@/utils/region.utils'
import { pretty } from '@/libs/texts'


export class ChargerUtils {

  public static getMaxPower(charger: SiteCharger[], list: ChargerStation[]): number {
    const mapData: number[] = (charger
      .flatMap(d => {
        return list.find(l => l.id === d.id)
      })
      .filter(d => d)
      .flatMap(d => {
        return (d?.chargerPoint || []).flatMap(c => c.connectors.map(d => d.output))
      }) || [])
      .map((d: any) => parseInt(d, 10))

    return Math.max(...mapData)

  }

  public static getConnectorTypeFromMaxPower(maxPowerkW: number): ChargerSpeed {
    // Return connector type matching max power
    if (maxPowerkW < 4) {
      return 'slow'
    } else if (maxPowerkW <= 25) {
      return 'fast'
    } else if (maxPowerkW <= 50) {
      return 'rapid'
    } else {
      return 'ultra_rapid'
    }
  }

  public static serialize(chargerStation: any): ChargerStation {
    const chargerPoint = chargerStation.charge_points || []
    return {
      id: chargerStation.id,
      title: chargerStation.title,
      archived: chargerStation.archived,
      chargerPoint,
    }
  }
  
  public static reportTemplateDictionary = (allStationChargers: ChargerStation[], siteChargers: SiteCharger[]) => {
    const siteChargerDictionary = siteChargers.reduce((acc: { [key: string]: SiteCharger[] }, item: any) => {
      if (!acc[item.siteId]) {
        acc[item.siteId] = item.collection
      } 
      return acc
    }, {} as { [key: string]: SiteCharger[] })

    const siteStationDictionary = allStationChargers.reduce((acc: { [key: string]: ChargerStation }, item: ChargerStation) => {
      if (!acc[item.id]) {
        acc[item.id] = item
      } 
      return acc
    }, {} as { [key: string]: ChargerStation })
  
    return {
      siteChargerDictionary,
      siteStationDictionary,
    }
  }

  public static getHighestActiveType(categorization: string[], mapping: ChargerSpeedMapping) {
    return ChargerUtils.getActiveTypes(categorization, mapping)[0]
  }

  public static getActiveTypes(categorization: string[], mapping: ChargerSpeedMapping | undefined): ChargerSpeed[] {
    const types: ChargerSpeed[] = []

    if (!mapping) {
      mapping = ozevChargerSpeeds
    }

    if (RegionUtils.isUk()) {
      // Extract numeric values from categorization while preserving order
      for (const value of categorization) {
        const power = parseInt(value, 10)
        if (!isNaN(power)) {
          for (const [speed, config] of Object.entries(mapping)) {
            if (power >= config.minPower && power <= config.maxPower && !types.includes(speed as ChargerSpeed)) {
              types.push(speed as ChargerSpeed)
            }
          }
        }
      }
    } else {
      if (categorization.includes('level_3_dc_fast') && !types.includes('level_3_dc_fast')) {
        types.push('level_3_dc_fast')
      }
      if (categorization.includes('level_2') && !types.includes('level_2')) {
        types.push('level_2')
      }
    }

    return types
  }

  public static getSpeedLabel(speed: string) {
    if (RegionUtils.isUs()) {
      return speed === 'level_3_dc_fast' ? 'Level 3 / DC Fast' : pretty(speed)
    } else if (RegionUtils.isUk()) {
      return pretty(speed)
    } else {
      if (speed === 'level_3_dc_fast') {
        return 'DC'
      } else {
        return 'AC'
      }
    }
  }

  public static getCategoryFromPower(power: number, mapping: ChargerSpeedMapping): ChargerSpeed {
    for (const category in mapping) {
      const config = mapping[category as ChargerUKSpeed]
      if (power >= config.minPower && power <= config.maxPower) {
        return category as ChargerSpeed
      }
    }
    return 'slow'
  }
}
