export interface MapData {
  map: google.maps.Map | null
}

export enum GoogleMapTypes {
  roadmap = 'roadmap',
  satellite = 'satellite',
  // same as satelite but includes street numbers on map
  hybrid = 'hybrid',
}

