import {ScoreColorPresentation, SiteAssessmentScore} from '@/models/SiteSelection/SiteScore.model'

export type SupportedRegion = RegionCode.UK | RegionCode.US

// Represents the UK
export const UK =
  'POLYGON((5.863740590767872 61.2742641903237, 5.863740590767872 47.90723621213962, -16.776678415579784 47.90723621213962, -16.776678415579784 61.2742641903237, 5.863740590767872 61.2742641903237))'

export type ChargerOwnership = 'own' | 'competition' | 'econnect'

export type ChargerUKSpeed = 'slow' | 'fast' | 'rapid' | 'ultra_rapid'
export type ChargerUSASpeed = 'level_2' | 'level_3_dc_fast'
export type ChargerSpeed = ChargerUKSpeed | ChargerUSASpeed

export interface ChargerSpeedConfig {
  minPower: number
  maxPower: number
  filterRange: string
  label: string
}

export type ChargerSpeedMapping = Record<ChargerUKSpeed, ChargerSpeedConfig>

export const legacyChargerSpeeds: ChargerSpeedMapping = {
  slow: { minPower: 0, maxPower: 3900, filterRange: '0-3900', label: '0 - 3.9kW' },
  fast: { minPower: 3901, maxPower: 9900, filterRange: '3901-9900', label: '3.9 - 9.9kW' },
  rapid: { minPower: 9901, maxPower: 50000, filterRange: '9901-50000', label: '9.9 - 50kW' },
  ultra_rapid: { minPower: 50001, maxPower: 99999999999, filterRange: '50001-99999999999', label: '50kW+' },
}
export const ozevChargerSpeeds: ChargerSpeedMapping = {
  slow: { minPower: 0, maxPower: 7999, filterRange: '0-7999', label: '0 - 8kW' },
  fast: { minPower: 8000, maxPower: 49999, filterRange: '8001-49999', label: '8 - 50kW' },
  rapid: { minPower: 50000, maxPower: 149999, filterRange: '50000-149999', label: '50 - 150kW' },
  ultra_rapid: { minPower: 150000, maxPower: 99999999999, filterRange: '150000-99999999999', label: '150kW+' },
}

export type ChargerSpeedScheme = 'Legacy' | 'OZEV'

export const chargerSpeedSchemes: Record<ChargerSpeedScheme, ChargerSpeedMapping> = {
  Legacy: legacyChargerSpeeds,
  OZEV: ozevChargerSpeeds,
}

/**
 * Describes the type of charger markers that can be seen inside a workspace.
 * Different from charger speed.
 */
export type ChargerMarkerType =
  | 'own'
  | 'proposed'
  | 'ai generated'
  | 'in review'
  | 'rejected'
  | 'approved'
  | 'competition'

export enum ChargerType {
  slow = 'slow',
  fast = 'fast',
  rapid = 'rapid',
  own = 'own',
  proposed = 'proposed',
  ultraRapid = 'ultra_rapid',
}

export interface ChargerIcon {
  icon: string,
  speed: string,
}

export interface CheckboxOption {
  label: string
  value: string
}

export interface CheckboxItem {
  value: string
  label?: string
  icon?: string
  disabled?: boolean
  iconSrc: string
  multipleItems?: boolean
  selectedSubOptionValue?: string,
}

export interface CheckboxSubOptionItem extends CheckboxItem {
  subOptions: CheckboxOption[]
  multipleItems: false
}

export interface MultipleCheckboxItem extends CheckboxItem {
  subOptions: CheckboxOption[][]
  multipleItems: true
}

type DnoTypes = 'enw' | 'spen' | 'ssen' | 'nien' | 'ng' | 'np' | 'ukpn'
type SubstationCategory = 'lss' | 'pss' | 'bps' | 'gsp'

export interface Substation {
  spn?: string
  category?: SubstationCategory
  last_updated?: string
  substation_type?: 'lss' | 'pss'
  name?: string
  label: string
  dno?: DnoTypes
  gsp?: string
  bsp?: string
  dhr_non_firm?: number
  dhr_firm?: number
  dhr?: number
  id?: string
  capacity_range: string
  hm_name: string
  in_v: string
  layerName: string
  out_v: string
  xref: string
  // this is a stringified json
  more_info: string
}

export interface FilterItem {
  value: string
  label?: string
  icon?: string
  selected?: boolean
  disabled?: boolean
  checkbox?: boolean
}

export declare interface POIFeatureProperties {
  category: string
  parentCategory?: string
  dwellTime?: string
  name?: string
}

export interface EvseInfo {
  evse_id: string
  evse_uid: string
  /**
   * Location Type as per OCPI 2.1.1
   */
  type?: string
  max_power_categorization: ChargerUKSpeed
  power_type_categorization: ChargerUSASpeed
  facilities?: string[]
  parking_restrictions?: string[]
  connectors: EvseConnector[]
  max_power: number
}

export interface EvseConnector {
  standard: string
  max_power: number
}

export interface ChargerUtilization {
  available: number | null
  charging: number | null
  inoperative: number | null
  exempt: number | null
}

export interface PredictedUtilizationFeatureProperties {
  charge_point_id: number
  value: number
}

export interface ChargerFeatureProperties {
  id: string
  name: string
  owner_name: string
  label?: string
  categorization: ChargerCategorization
  cp_type?: ChargerSpeed // deprecated. used for our chargers only
  evse_count: number
  
  address: string
  max_power: number
  evse_info: EvseInfo[]
  operator_name: string
  utilization: ChargerUtilization
  agnostic_categorization: string[] // This is an array of max powers and level_2/level_3
  date_updated?: string
  last_available_session_date?: string
}

export declare interface WardFeatureProperties {
  name: string
  id: number
  boundingbox: google.maps.LatLngBounds
  centroid: {
    type: 'Point'
    coordinates: [number, number]
  }
}

export interface RegionFeatureProperties {
  id: number
  name: string
  display_name: string
  polygon?: google.maps.Polygon
  bbox?: google.maps.LatLngBounds
  center?: google.maps.LatLng
}

interface PolygonProperties {
  polygon: google.maps.Polygon
  bbox: google.maps.LatLngBounds
  center: google.maps.LatLng
}

export interface AreaProperties extends PolygonProperties {
  id: number
  name: string
  display_name: string
  parent_id?: number
}

export interface CountryProperties extends Omit<AreaProperties, 'polygon' | 'bbox' | 'center'> {
  bounding_box: string
  centroid: {
    type: 'Point'
    coordinates: [number, number]
  }
}

export type DwellTimeType = 'short' | 'long'

export interface POICategory {
  name: string
  parent: string
  dwellTime: DwellTimeType 
  count?: number
}

export interface Location {
  zoom: number
  center: { lat: number; lng: number }
  label?: string
}

export interface ChargePoint {
  charge_point_id: number
  assessment_name: string
  aggregate_score_name: SiteAssessmentScore,
  aggregate_score_color: ScoreColorPresentation,

  // unfortunately we can not bet on consistency of the data
  // structure we get from the backend
  value?: number
  utilisation?: number
  utilization?: number
}

export type MapType = 'roadmap' | 'satellite' | 'hybrid'

export type ScaleType = 'linear' | 'logarithmic' | 'exponential' | 'sqrt'

export interface ChargerDetails {
  categorization: ChargerCount
}

export interface ChargerCategorization {
  slow: boolean
  fast: boolean
  rapid: boolean
  ultra_rapid: boolean
  level_2: boolean
  level_3_dc_fast: boolean
}


export interface ChargerCountPresentation {
  visible:ChargerCount
  all_for_region: ChargerCount
}


export interface ChargerCount {
  slow: number
  fast: number
  rapid: number
  ultra_rapid: number
  level_2: number
  level_3_dc_fast: number
}

export interface ChargerCountResponse {
  [key: number]: number
  level_2: number
  level_3_dc_fast: number
}

export enum RegionCode {
  UK = 'UK',
  US = 'US',
}

export enum CountryCode {
  UK = 'UK',
  US = 'US',
  IT = 'IT',
  DE = 'DE',
  IE = 'IE',
  FR = 'FR',
  ES = 'ES',
  NL = 'NL',
  BE = 'BE',
  PL = 'PL',
  CA = 'CA',
  CH = 'CH',
  AT = 'AT',
  SE = 'SE',
  SI = 'SI',
  AU = 'AU',
}

export const countryI18n = new Map([
  [CountryCode.UK, 'en-gb'],
  [CountryCode.US, 'en-us'],
])

export interface AppRegionData {
  url: string
  countryCode: SupportedRegion
}

export enum EVCSTime {
  THIRTY = 0,
  NINETY = 1,
  ONE_HUNDRED_EIGHTY = 2
}

export const chargerTimeMapper = new Map([
  [EVCSTime.THIRTY, 30],
  [EVCSTime.NINETY, 90],
  [EVCSTime.ONE_HUNDRED_EIGHTY, 180],
])

export interface UiCheckbox {
  checked: boolean
}

export interface UtilizationEntry {
  inoperative: number;
  charging: number;
  available: number;
  exempt: number;
}

export interface UtilizationData {
  external_evse_id: string;
  utilization: UtilizationEntry
}
export type SubstationInfoItem = { text: string, property: keyof Substation, rounded?: number }

export const allAvailableSubstationFeatures: SubstationInfoItem[] = [
  {
    text: 'Spn',
    property: 'spn',
  },
  {
    text: 'Demand headroom (MW)',
    rounded: 2,
    property: 'dhr',
  },
  {
    text: 'Firm demand headroom (MW)',
    rounded: 2,
    property: 'dhr_firm',
  },
  {
    text: 'Non-Firm demand headroom (MW)',
    rounded: 2,
    property: 'dhr_non_firm',
  },
  {
    text: 'BSP',
    property: 'bsp',
  },
  {
    text: 'GSP',
    property: 'gsp',
  },
  {
    text: 'DNO',
    property: 'dno',
  },
  {
    text: 'Capacity',
    property: 'capacity_range',
  },
  {
    text: 'category',
    property: 'category',
  },
  { text: ' HM name',
    property: 'hm_name',
  },
  { text: 'id',
    property: 'id',
  },
  { text: 'in voltage',
    property: 'in_v',
  },
  { text: 'label',
    property: 'label',
  },
  { text: 'last updated',
    property: 'last_updated',
  },
  { text: 'layer name',
    property: 'layerName',
  },
  { text: 'name',
    property: 'name',
  },
  { text: 'Out voltage',
    property: 'out_v',
  },
  { text: 'xref',
    property: 'xref',
  },
]
