import {AreaProperties} from '@/types/app'
import {Feature, MultiPolygon, Polygon} from 'geojson'
import {_RouteLocationBase} from 'vue-router'

export function num2LatLngLit(c: [number, number]): google.maps.LatLngLiteral {
  const [lng, lat] = c
  return { lat, lng }
}

export function swne2LatLngBounds(lit: google.maps.LatLngBoundsLiteral): google.maps.LatLngBounds {
  return new google.maps.LatLngBounds(
    new google.maps.LatLng(lit.south, lit.west),
    new google.maps.LatLng(lit.north, lit.east),
  )
}

/**
 * Returns a WKT representation of the given GeoJSON.
 *
 * https://en.wikipedia.org/wiki/Well-known_text_representation_of_geometry
 */
export function geoJsonToWkt(geometry: Polygon | MultiPolygon): string {
  if (geometry.type === 'Polygon') {
    return `POLYGON((${geometry.coordinates[0]
      .map((point) => point.join(' '))
      .join(', ')}))`
  }

  return `MULTIPOLYGON(${geometry.coordinates.map((polygon) =>
    '((' +
      polygon.map((coordinates) =>
        coordinates.map((c) => c.join(' ')).join(', ')) +
      '))')})`
}

export function boxToBounds(box: string): google.maps.LatLngBounds | undefined {
  const pattern = /BOX\((-?\d*\.?\d+) (-?\d*\.?\d+),(-?\d*\.?\d+) (-?\d*\.?\d+)\)/

  const matches = box.match(pattern)

  if (!matches) {
    return
  }

  const [, lng1, lat1, lng2, lat2] = matches.map(m => +m)
  return new google.maps.LatLngBounds(
    {lat: lat1, lng: lng1},
    {lat: lat2, lng: lng2},
  )
}

/**
 * Checks if the app is currently in workspace view
 * @param route
 */

export function isWorkspaceFlow(route: _RouteLocationBase): boolean {
  return isWorkspace(route) || route.name === 'workspace/site-list' 
}

export function isPortfolioFlow(route: _RouteLocationBase): boolean {
  return isPortfolio(route) || route.name === 'portfolio/list' 
}

export function isWorkspace(route: _RouteLocationBase): boolean {
  return route.name === 'workspace'
}

export function isSiteList(route: _RouteLocationBase): boolean {
  return route.name === 'workspace/site-list' || route.name === 'portfolio/list' 
}

export function selectCorrectSiteListRoute(route: _RouteLocationBase): string {
  if (route.name === 'workspace') {
    return 'workspace/site-list'
  }
  
  return 'portfolio/list' 
}


export function selectCorrectSiteListRedirect(route: _RouteLocationBase): string {
  if (route.name === 'workspace/site-list') {
    return 'workspace'
  }
  return 'portfolio'
}

export function isWorkspaceEditor(route: _RouteLocationBase): boolean {
  return route.name === 'workspace/editor'
}

export function isWorkspaceSiteDetail(route: _RouteLocationBase): boolean {
  return route.name === 'workspace/site-details'
}

export function isPortfolio(route: _RouteLocationBase): boolean {
  return ((route.name || '') as string).includes('portfolio')
}

export async function base64ToBuffer(base64: string): Promise<ArrayBuffer> {
  const dataUrl = 'data:application/octet-binary;base64,' + base64
  const image = await fetch(dataUrl)
  return image.arrayBuffer()
}

export const wait = (ms: number): Promise<void> =>
  new Promise((resolve) => setTimeout(resolve, ms))

export function toPresentableArea(data: Feature<Polygon | MultiPolygon>): Feature<Polygon | MultiPolygon, AreaProperties> {
  let geometry = data.geometry
  if (geometry.type === 'Polygon') {
    const aux = geometry.coordinates
    if (typeof aux[0][0][0] !== 'number') {
      geometry = {type: 'MultiPolygon', coordinates: [aux]}
    }
  }

  const paths: google.maps.LatLng[] = []
  const bbox = new google.maps.LatLngBounds();
  [
    ...(geometry.coordinates.flat(geometry.type === 'Polygon' ? 1 : 2) as [number, number][]),
  ].forEach(([lng, lat]) => {
    const ll = new google.maps.LatLng(lat, lng)
    bbox.extend(ll)
    paths.push(ll)
  })

  return {
    properties: {
      polygon: new google.maps.Polygon({paths}),
      bbox: bbox,
      center: bbox.getCenter(),
      name: data.properties!.name,
      display_name: data.properties!.display_name,
      id: data.properties!.id as number,
    },
    id: data.properties!.id,
    type: 'Feature',
    geometry: geometry,
  }
}


export const timeout = <T>(prom: Promise<T>, time: number, exception: Error) => {
  let timer: any
  return Promise.race([
    prom,
    new Promise((_r, rej) => {
      timer = setTimeout(() => {
        rej(exception)
      }, time)
    }),
  ]).finally(() => clearTimeout(timer))
}

export function base64ToFile(base64String: string, fileName: string): File {
  // Split the Base64 string to separate the metadata from the data part
  const parts = base64String.split(';base64,')
  const mimeType = parts[0].split(':')[1]
  const data = parts[1]

  // Convert the Base64 data to a byte array
  const byteCharacters = atob(data)
  const byteNumbers = new Array(byteCharacters.length)
  for (let i = 0; i < byteCharacters.length; i++) {
    byteNumbers[i] = byteCharacters.charCodeAt(i)
  }
  const byteArray = new Uint8Array(byteNumbers)

  // Create a Blob from the byte data
  const blob = new Blob([byteArray], { type: mimeType })

  // Create a File object from the Blob
  return new File([blob], fileName, { type: mimeType })
}
