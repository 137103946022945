import {IconDefinition, icons} from '@/types/icons'
import {
  ChargerFeatureProperties,
  ChargerIcon,
  ChargerMarkerType,
  ChargerOwnership,
  ChargerSpeed,
  ChargerSpeedMapping,
} from '@/types/app'

import sharedComponentsUtils from '@/utils/sharedComponents.utils'
import {VectorUtils} from '@/utils/vector.utils'
import GlobalUtils from '@/utils/global.utils'
import {pretty} from '@/libs/texts'
import {ChargerUtils} from '@/utils/ChargerUtils'
import {RegionUtils} from '@/utils/region.utils'

const svgNamespace = 'http://www.w3.org/2000/svg'

export interface Config {
  id: string
  fallbackId?: string
  fill?: string
  stroke?: string
  label?: string
  // stroke is by default set to be white
  badge?: {
    fill: string
  }
}

/**
 * Gets the webpack url of the specified asset
 */
export function getAssetUrl(path: string): string {
  return GlobalUtils.getLocalImage(path)
}

export function getAssetFromPublicUrl(path: string): string {
  return new URL(path, import.meta.url).href
}

export function chargerIcon(type: ChargerMarkerType, speed: ChargerSpeed = 'slow'): string {
  function siteTypeMapper(): string {
    switch (type.toLowerCase()) {
      case 'in review':
      case 'verified':
        return 'review'
      case 'own':
        return 'own'
      case 'proposed':
        return 'proposed'
      case 'ai generated':
        return 'ai generated'
      case 'rejected':
        return 'rejected'
      case 'approved':
        return 'approved'
      case 'competition':
        return 'competition'
      default:
        return 'review'
    }
  }

  const url = type === 'ai generated' ? 'charger-proposed-generated.svg' : `charger-${siteTypeMapper()}-${speed}.svg`

  return getAssetUrl('/assets/map/markers/' + url)
}

export function inline(config: Config): string {
  return uriEncode(assemble(config).outerHTML)
}

export function svg(config: Config): string {
  return assemble(config).outerHTML
}

function uriEncode(str: string): string {
  return 'data:image/svg+xml;charset=UTF-8,' + encodeURIComponent(str)
}

function assemble(cfg: Config): SVGElement {
  let ico: IconDefinition
  const size = [64, 64]
  const center = 3.8

  const { id = cfg.fallbackId, fallbackId = 'default', fill = '#FFFFFF', stroke } = cfg

  if (id !== undefined && icons[id] !== undefined) {
    ico = icons[id]
  } else {
    ico = icons[fallbackId]
  }

  const layers: SVGElement[] = []
  if (cfg.badge !== undefined) {
    layers.push(svgNode('circle', {
      fill: cfg.badge.fill,
      stroke: '#FFFFFF',
      'stroke-width': '4',
      cx: '32',
      cy: '32',
      r: '30',
    }))
  }

  const [width, height] = size

  const root = svgNode(
    'svg',
    {
      width: `${width}px`,
      height: `${height}px`,
      viewBox: `0 0 ${width} ${height}`,
    },
    ...layers,
  )

  root.setAttribute('xmlns', svgNamespace)

  const [x, y] = ico.offset || [0, 0]
  const transform = `scale(2, 2) translate(${center + x}, ${center + y})`
  const grp = svgNode('g', { transform })

  if (ico.rawSvg) {
    for (let i = 0; i < ico.rawSvg.length; i++) {
      const args = { ...ico.rawSvg[i].args, stroke: stroke ? stroke : fill, fill }
      grp.append(svgNode(ico.rawSvg[i].tag, args))
    }
  }

  for (let i = 0; i < ico.path.length; i++) {
    grp.append(svgNode('path', { d: ico.path[i], fill, stroke: stroke ? stroke : fill }))
  }

  root.append(grp)

  return root
}

/**
 * Combines a charger icon with an EVSE count icon
 *
 * Returns a URI encoded string of the SVG content
 **/
export function combineSvg(chargerIcon: string, countIcon: string): string {
  // We are creating two new groups which are in the end are inserted into the svg
  // where the first group contains the charger icon and the second group contains
  // the number of chargers

  const size = chargerIcon.match(/width="(\d+)px"/)?.[1] ?? 64

  const chargerIconGroup = document.createElement('g')
  chargerIconGroup.innerHTML = chargerIcon

  const counterIconGroup = document.createElement('g')
  counterIconGroup.innerHTML = countIcon

  const newSvg = `<svg xmlns="http://www.w3.org/2000/svg" width="${size}px" height="${size}px">${[
    // the replaceAll is used for fixing the issue of the svg gradient element and property name being
    // set to lower caser rather than cameCase
    chargerIconGroup.outerHTML
      .replaceAll('lineargradient', 'linearGradient')
      .replaceAll('gradientunits', 'gradientUnits'),
    counterIconGroup.outerHTML,
  ].join('')}</svg>`

  return uriEncode(newSvg)
}

/**
 * Returns a URI encoded string of a charger icon SVG element
 * based on its type and speed, with an EVSE count on top of it
 */
export function createChargerIcon(
  chargerType: ChargerOwnership,
  speeds: ChargerSpeed[],
  evseCount: number,
  filter: ChargerSpeed[] = [],
): string {
  const filteredCpTypes = speeds
    .filter((key) => filter.length === 0 || filter.includes(key))

  const highestChargerSpeed = filteredCpTypes[0] ?? 'slow'
  const { chargerIcon, evseCountIcon } = selectChargerIcons(chargerType, highestChargerSpeed, evseCount)
  return combineSvg(chargerIcon, evseCountIcon)
}

export function createAllChargerIcons(
  chargerType: ChargerOwnership,
  properties: ChargerFeatureProperties,
  mapping: ChargerSpeedMapping,
): ChargerIcon[] {
  const activeTypes = ChargerUtils.getActiveTypes(properties.agnostic_categorization, mapping)

  const evseInfo = properties.evse_info

  return activeTypes.map((chargerSpeed) => {
    const evseCount = evseInfo.filter(info => {
      if (RegionUtils.isUk()) {
        return ChargerUtils.getCategoryFromPower(info.max_power, mapping) === chargerSpeed
      } else {
        return info.power_type_categorization === chargerSpeed
      }
    }).length

    const {chargerIcon, evseCountIcon} = selectChargerIcons(chargerType, chargerSpeed, evseCount)
    return {icon: combineSvg(chargerIcon, evseCountIcon), speed: pretty(chargerSpeed)}
  })
}

export function createChargerGraphIcon(
  chargerType: ChargerOwnership,
  chargerSpeed: ChargerSpeed,
  evseCount: number,
  graphValues: [number, number],
  graphColors: [string, string],
): string {
  const { chargerIcon, evseCountIcon } = selectChargerIcons(chargerType, chargerSpeed, evseCount)
  return combineSvgEvcs(graphValues, graphColors, chargerIcon, evseCountIcon)
}

const selectChargerIcons = (
  chargerType: ChargerOwnership,
  chargerSpeed: ChargerSpeed,
  evseCount: number,
) => {
  let chargerIcon: string = ''

  // I wish there was a cleaner/dynamic way to do this, but unfortunately it can't be done differently
  // due to loader imports being resolved at compile time.
  if (chargerType === 'own') {
    switch (chargerSpeed) {
      case 'slow':
        chargerIcon = sharedComponentsUtils.ChargerOwnSlow
        break
      case 'fast':
        chargerIcon = sharedComponentsUtils.ChargerOwnFast
        break
      case 'rapid':
        chargerIcon = sharedComponentsUtils.ChargerOwnRapid
        break
      case 'ultra_rapid':
        chargerIcon = sharedComponentsUtils.ChargerOwnUltraRapid
        break
    }
  } else if (chargerType === 'econnect') {
    switch (chargerSpeed) {
      case 'slow':
        chargerIcon = sharedComponentsUtils.ChargerEconnectSlow
        break
      case 'fast':
        chargerIcon = sharedComponentsUtils.ChargerEconnectFast
        break
      case 'rapid':
        chargerIcon = sharedComponentsUtils.ChargerEconnectRapid
        break
      case 'ultra_rapid':
        chargerIcon = sharedComponentsUtils.ChargerEconnectUltraRapid
        break
    }
  } else {
    switch (chargerSpeed) {
      case 'slow':
        chargerIcon = sharedComponentsUtils.ChargerCompetitionSlow
        break
      case 'fast':
      case 'level_2':
        chargerIcon = sharedComponentsUtils.ChargerCompetitionFast
        break
      case 'rapid':
      case 'level_3_dc_fast':
        chargerIcon = sharedComponentsUtils.ChargerCompetitionRapid
        break
      case 'ultra_rapid':
        chargerIcon = sharedComponentsUtils.ChargerCompetitionUltraRapid
        break
    }
  }

  return {
    evseCountIcon: chargerCountIcon(evseCount),
    chargerIcon,
  }
}

function svgNode(tag: string, args: Record<string, string | number | undefined> = {}, ...children: SVGElement[]): SVGElement {
  const el = document.createElementNS(svgNamespace, tag)

  for (const arg in args) {
    if (args[arg] !== undefined) {
      el.setAttributeNS(null, arg, args[arg] as string)
    }
  }

  el.append(...children)

  return el
}

export function catalogueItemIcon(id: string, fill = '#B0B7BF', badge?: Config['badge'], stroke?: string): string {
  return inline({ id, fill, badge, stroke })
}
// Custom version for own evcs valuse


export function combineSvgEvcs(utilizationScore: [number, number], colors: string[], ...svgs: any[]): string {
  // We are creating two new groups which are in the end are inserted into the svg
  // where the first group contains the charger icon and the second group contains
  // the number of chargers

  const chargerIconGroup = document.createElement('g')
  chargerIconGroup.setAttribute('transform', 'scale(0.9) translate(8 8)')
  chargerIconGroup.innerHTML = svgs[0]

  const counterIconGroup = document.createElement('g')

  counterIconGroup.innerHTML = svgs[1]

  const g = counterIconGroup.firstChild as HTMLElement
  g.setAttribute('transform', 'translate(30 0)')

  const donutGroup = document.createElement('g')

  donutGroup.innerHTML = VectorUtils.generateDonutSvg(utilizationScore, colors)


  const newSvg = `<svg xmlns="http://www.w3.org/2000/svg" width="44px" height="44px" viewBox="0 0 44px 44px">${[
    donutGroup.outerHTML,
    chargerIconGroup.outerHTML,
    counterIconGroup.outerHTML,
  ].join('')}</svg>`

  return uriEncode(newSvg)
}

export function coloredPlanningSiteMarker(fill: string, stroke = '#fff'): string {
  return uriEncode(coloredSiteMarkerWithLightningIcon(fill, stroke, 64,'translate(0 0) scale(2 2)'))
}

export function coloredSiteMarker(color: string, size = 32, transform = 'translate(0 0) scale(1 1)'): string {
  return uriEncode(`
    <svg width="${size}" height="${size}" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g transform="${transform}">
        <path d="M16 1c6.334 0 12 4.684 12 11.918 0 2.238-.89 4.77-2.813 7.62-1.92 2.846-4.829 5.949-8.76 9.309a.693.693 0 0 1-.87 0c-3.923-3.36-6.828-6.463-8.746-9.31C4.891 17.689 4 15.158 4 12.919 4 5.684 9.666 1 16 1Z" fill="${color}" stroke="#fff" stroke-width="2"/>
      </g>
    </svg>
  `)
}

export function coloredSiteMarkerWithLightningIcon(fill: string, stroke = '#fff', size = 32, transform = 'translate(0 0) scale(0.5 0.5)'): string {
  return (`
    <svg width="${size}px" height="${size}px" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g transform="${transform}">
        <path fill="${fill}" stroke="${stroke}" stroke-width="2" d="M16 1C22.3337 1 28 5.68416 28 12.9183C28 15.1562 27.1093 17.6878 25.1871 20.5374C23.2672 23.3835 20.3583 26.4867 16.4263 29.8469C16.1818 30.0509 15.8025 30.051 15.5578 29.8472C11.634 26.4871 8.7291 23.3839 6.81116 20.5378C4.89077 17.688 4 15.1563 4 12.9183C4 5.68416 9.66629 1 16 1Z"/>
        <path fill="#FFFFFF" d="M18.3504 5.36116L18.0565 6.801L17.9299 7.4014L16.948 12.2005H20.7024C20.8137 12.199 20.9162 12.2621 20.9673 12.3629C21.0184 12.464 21.009 12.5857 20.9434 12.6774H20.9432L19.4733 14.7156L18.0034 16.7537L15.0635 20.83L14.6637 21.3753L13.5788 22.8752C13.495 22.9941 13.3394 23.0338 13.2105 22.9692C13.0817 22.9046 13.0175 22.7547 13.0584 22.6142L13.5171 21.0515L13.6817 20.4759L15.0193 15.7996H11.2944C11.1856 15.7998 11.0855 15.7387 11.0344 15.6407C10.9834 15.5426 10.9894 15.4237 11.0504 15.3317L16.4951 7.1191L16.8804 6.55791L17.8182 5.13314C17.8966 5.01391 18.0467 4.96783 18.1764 5.02341C18.3062 5.07899 18.3791 5.22054 18.3504 5.36116Z"/>
      </g>
    </svg>
  `)
}

export function generatedSiteMarkerIcon(size = 64): string {
  return `
    <svg xmlns="http://www.w3.org/2000/svg" width="${size}px" height="${size}px" >
      <defs>
        <linearGradient id="linear0" gradientUnits="userSpaceOnUse" x1="16" y1="0" x2="16" y2="31" >
          <stop offset="0" style="stop-color:rgb(29.803922%,51.372549%,77.254902%);stop-opacity:1;"/>
          <stop offset="1" style="stop-color:rgb(11.372549%,19.607843%,27.843137%);stop-opacity:1;"/>
        </linearGradient>
      </defs>
    
      <g id="surface1" transform="scale(${size / 64})">
        <path style="fill-rule:nonzero;fill:url(#linear0);stroke-width:2;stroke-linecap:butt;stroke-linejoin:miter;stroke:rgb(100%,100%,100%);stroke-opacity:1;stroke-miterlimit:4;" d="M 16 1 C 22.333984 1 28 5.683594 28 12.917969 C 28 15.15625 27.109375 17.6875 25.1875 20.537109 C 23.267578 23.382812 20.357422 26.486328 16.425781 29.847656 C 16.181641 30.050781 15.802734 30.050781 15.558594 29.847656 C 11.634766 26.486328 8.728516 23.384766 6.810547 20.537109 C 4.890625 17.6875 4 15.15625 4 12.917969 C 4 5.683594 9.666016 1 16 1 Z M 16 1 " transform="matrix(2,0,0,2,0,0)"/>
        <path style=" stroke:none;fill-rule:nonzero;fill:rgb(100%,100%,100%);fill-opacity:1;" d="M 28.882812 19.855469 L 24.242188 34 L 20.503906 34 L 26.84375 16.9375 L 29.222656 16.9375 Z M 32.738281 34 L 28.085938 19.855469 L 27.710938 16.9375 L 30.113281 16.9375 L 36.488281 34 Z M 32.527344 27.648438 L 32.527344 30.402344 L 23.515625 30.402344 L 23.515625 27.648438 Z M 41.808594 16.9375 L 41.808594 34 L 38.304688 34 L 38.304688 16.9375 Z M 41.808594 16.9375 "/>
      </g>
    </svg>
  `
}

export function chargerCountIcon(count: number, size = 64): string {
  const factor = size / 64
  const width = count > 8 ? 32 : 20

  if (count === 0) {
    return ''
  }

  return `
    <svg width="${size}px" height="${size}px" xmlns="http://www.w3.org/2000/svg">
      <g transform="translate(${size - (width * factor)}) scale(${factor})">
        <rect x="0" y="0" width="${width}" height="22" rx="10" fill="white"/>
        <text x="4" y="18" font-size="20px" fill="#212529" font-weight="bold" font-family="'Roboto', sans-serif">${count > 8 ? '8+' : count}</text>
      </g>
    </svg>
  `
}
