import {
  ColoringFunctions,
  ColoringModeDatasetData,
  MVTLayerConfiguration,
  MapLayerTags,
} from '@/utils/layers/layout-config'
import { LayerRole } from '@/auth/roles'
import { trafficDensityLayer } from '@/utils/layers/layers-uk'
import { GeometryDensity, GeometryType, GeometryZoomLevels } from '@/utils/layers/layout-vector-config'
import {Feature, GeoJsonProperties, Geometry} from 'geojson'


export const layersDE:MVTLayerConfiguration[] = [
  new MVTLayerConfiguration({
    minZoom: GeometryZoomLevels.normalGeometry,
    name: 'Population density (100m grid, persons per square km)',
    group: 'Demographic data',
    description: 'Population density (2011 Census, 100m grid, persons per square km)',
    dataset: 'Population density (2011 Census, 100m grid, persons per square km)',
    content_type: {type: GeometryType.polygon, density: GeometryDensity.dense},
    coloring_mode: ColoringModeDatasetData.gradientAlgorithm,
    tag: MapLayerTags.layerTab,

    tooltipProperties: [
      {
        text: 'Density',
        property: 'value',
        rounded: 2,
      },
    ],
  }),
  new MVTLayerConfiguration({
    minZoom: GeometryZoomLevels.normalGeometry,
    name: 'Population density (Municipality level, persons per square km)',
    group: 'Demographic data',
    description: 'Population density (2011 Census, 100m grid, persons per square km)',
    dataset: 'Population density (2011 Census, Municipality level, persons per square km)',
    content_type: {type: GeometryType.polygon, density: GeometryDensity.dense},
    coloring_mode: ColoringModeDatasetData.gradientAlgorithm,
    tag: MapLayerTags.layerTab,
    tooltipProperties: [
      {
        text: 'Density',
        property: 'value',
        rounded: 2,
      },
    ],
  }),
  new MVTLayerConfiguration({
    minZoom: GeometryZoomLevels.normalGeometry,
    name: 'Disposable income',
    group: 'Demographic data',
    description: 'Disposable income of private households (EUR)',
    dataset: 'Disposable income of private households (EUR)',
    content_type: {type: GeometryType.polygon, density: GeometryDensity.dense},
    coloring_mode: ColoringModeDatasetData.gradientAlgorithm,
    tag: MapLayerTags.layerTab,
    tooltipProperties: [
      {
        text: 'District',
        property: 'district',
      },
      {
        text: 'Disposable income',
        property: 'value',
        rounded: 2,
      },
    ],
  }),

  new MVTLayerConfiguration({
    role: LayerRole.trafficDensity,
    name: trafficDensityLayer,
    group: 'Traffic data',
    description: 'Display daily average traffic density.',
    minZoom: GeometryZoomLevels.normalGeometry,
    dataset: 'TRAFFIC_DENSITY',
    content_type: {type: GeometryType.line, density: GeometryDensity.dense},
    coloring_mode: ColoringModeDatasetData.colorFunction,
    tag: MapLayerTags.layerTab,
    color: new ColoringFunctions(
      [0,0,0,0],
      (feature: Feature<Geometry, GeoJsonProperties>): [number, number, number, number] => {
        const propertyValue = feature.properties?.w || 0
        if (propertyValue <= 180) {
          return [244, 233, 154, 255]
        } else if (propertyValue > 180 && propertyValue <= 210) {
          return [215, 174, 101, 255]
        } else if (propertyValue > 210 && propertyValue <= 350) {
          return [194, 122, 108, 255]
        } else if (propertyValue > 350 && propertyValue <= 970) {
          return [228, 149, 120, 255]
        } else if (propertyValue > 970 && propertyValue <= 3300) {
          return [156, 108, 221, 255]
        } else {
          return [32, 99, 242, 255]
        }
      },
    ),
    mapKeyOverride: [
      { color: ['#F4E99A'], titleLeft: '0', titleRight: '180' },
      { color: ['#D7AE65'], titleLeft: '180', titleRight: '210' },
      { color: ['#C27A6C'], titleLeft: '210', titleRight: '350' },
      { color: ['#E49578'], titleLeft: '350', titleRight: '970' },
      { color: ['#9C6CDD'], titleLeft: '970', titleRight: '3,300' },
      { color: ['#2063F2'], titleLeft: '3,300', titleRight: '95,900' },
    ],
    tooltipProperties: [
      {
        property: 'w',
        text: 'Whole week daily average',
        rounded: 0,
      },
      {
        property: 'we',
        text: 'Weekend daily average',
        rounded: 0,
      },
      {
        property: 'wd',
        text: 'Weekday daily average',
        rounded: 0,
      },
    ],
  }),
  new MVTLayerConfiguration({
    minZoom: GeometryZoomLevels.normalGeometry,
    name: 'Detached houses ratio',
    group: 'Demographic data',
    description: 'Ratio of detached houses (2011 Census, 100m grid)',
    dataset: 'Detached houses percent',
    content_type: {type: GeometryType.polygon, density: GeometryDensity.dense},
    coloring_mode: ColoringModeDatasetData.gradientAlgorithm,
    tag: MapLayerTags.layerTab,
    tooltipProperties: [
      {
        text: 'Ratio',
        property: 'value',
        rounded: 2,
      },
    ],
  }),
  new MVTLayerConfiguration({
    minZoom: GeometryZoomLevels.normalGeometry,
    name: 'Semi-detached houses ratio',
    group: 'Demographic data',
    description: 'Ratio of semi-detached houses (2011 Census, 100m grid)',
    dataset: 'Semi-detached houses percent',
    content_type: {type: GeometryType.polygon, density: GeometryDensity.dense},
    coloring_mode: ColoringModeDatasetData.gradientAlgorithm,
    tag: MapLayerTags.layerTab,
    tooltipProperties: [
      {
        text: 'Ratio',
        property: 'value',
        rounded: 2,
      },
    ],
  }),
  new MVTLayerConfiguration({
    minZoom: GeometryZoomLevels.normalGeometry,
    name: 'Terraced houses ratio',
    group: 'Demographic data',
    description: 'Ratio of terraced houses (2011 Census, 100m grid)',
    dataset: 'Terraced houses percent',
    content_type: {type: GeometryType.polygon, density: GeometryDensity.dense},
    coloring_mode: ColoringModeDatasetData.gradientAlgorithm,
    tag: MapLayerTags.layerTab,
    tooltipProperties: [
      {
        text: 'Ratio',
        property: 'value',
        rounded: 2,
      },
    ],
  }),
  new MVTLayerConfiguration({
    minZoom: GeometryZoomLevels.normalGeometry,
    name: 'Multi-family buildings ratio',
    group: 'Demographic data',
    description: 'Ratio of multi-family buildings (2011 Census, 100m grid)',
    dataset: 'Multi family buildings percent',
    content_type: {type: GeometryType.polygon, density: GeometryDensity.dense},
    coloring_mode: ColoringModeDatasetData.gradientAlgorithm,
    tag: MapLayerTags.layerTab,
    tooltipProperties: [
      {
        text: 'Ratio',
        property: 'value',
        rounded: 2,
      },
    ],
  }),

  new MVTLayerConfiguration({
    name: 'EV registrations',
    dataset: 'VEHICLE_REGISTRATION',
    group: 'Traffic data',
    description: 'Percentage of new car registrations being electric vehicles, by municipality, 2024',
    minZoom: GeometryZoomLevels.sparseGeometry,
    coloring_property: 'percentage_of_ev_latest',
    content_type: {type: GeometryType.polygon, density: GeometryDensity.dense},
    coloring_mode: ColoringModeDatasetData.gradientAlgorithm,
    tag: MapLayerTags.layerTab,
    tooltipProperties: [
      {
        property: 'gemeinde',
        text: 'municipality',
      },
      {
        property: 'percentage_of_ev_latest',
        text: 'percentage of EV registrations',
      },
    ],
  }),
  new MVTLayerConfiguration({
    name: 'Registered vehicles per capita',
    group: 'Traffic data',
    description: 'Total registered vehicles per capita, by municipality, 2024',
    minZoom: GeometryZoomLevels.sparseGeometry,
    coloring_property: 'personal_vehicles_per_capita',
    dataset: 'VEHICLE_REGISTRATION',
    content_type: {type: GeometryType.polygon, density: GeometryDensity.dense},
    coloring_mode: ColoringModeDatasetData.gradientAlgorithm,
    tag: MapLayerTags.layerTab,
    tooltipProperties: [
      {
        property: 'gemeinde',
        text: 'municipality/state',
      },
      {
        property: 'personal_vehicles_per_capita',
        text: 'vehicles per capita',
        rounded: 2,

      },
    ],
  }),
  new MVTLayerConfiguration({
    name: 'EV registrations change 2022-2024',
    group: 'Traffic data',
    description: 'Change in percentage of new car registrations being electric vehicles, by municipality, between 2022 - 2024',
    minZoom: GeometryZoomLevels.sparseGeometry,
    coloring_property: 'ev_registration_change',
    dataset: 'VEHICLE_REGISTRATION',
    content_type: {type: GeometryType.polygon, density: GeometryDensity.dense},
    coloring_mode: ColoringModeDatasetData.gradientAlgorithm,
    tag: MapLayerTags.layerTab,
    tooltipProperties: [
      {
        property: 'gemeinde',
        text: 'municipality/state',
      },
      {
        property: 'ev_registration_change',
        text: 'change in EV registrations',
      },
    ],
  }),

]

