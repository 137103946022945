import {defineStore} from 'pinia'
import {ChargerOwnership, EVCSTime} from '@/types/app'
import {OrganizationConfiguration} from '@/libs/auth/types'

export enum ResourceLoadingKey {
  SiteSources = 'site sources',
  SiteStatuses = 'site statuses',
  CustomFields = 'custom fields',
  OrganizationUsers = 'organization users',
  WorkspaceList = 'workspace list',
  GenericDatasets ='generic datasets',
  LabelSets = 'label sets',
  ChargerDetails = 'Charger details',
  ParkingOperators = 'parking operators',
  ParkingCategories = 'parking categories',
}

export const useAppStore = defineStore('app', {
  state: () => ({
    datasetUrl: '',
    location: {
      zoom: 12,
      center: { lat: 53.4200904616, lng: -2.3299764318 },
      label: 'Manchester, Great Britain',
    } as OrganizationConfiguration['start_location'],
    region: null,
    loadingStack: new Map(),
    evcsDuration: EVCSTime.THIRTY,
    eConnectDuration: EVCSTime.THIRTY,
  }),

  getters: {
    loading(): boolean {
      return this.loadingStack.size > 0
    },
  },

  actions: {
    setLoading(id: string, isLoading: boolean) {
      if (isLoading) {
        this.loadingStack.set(id, true)
      } else if (this.loadingStack.has(id)) {
        this.loadingStack.delete(id)
      }
    },

    isLoading(id: string): boolean {
      return this.loadingStack.has(id)
    },

    getEvcsDuration(ownerType: ChargerOwnership): EVCSTime {
      return ownerType === 'own' ? this.evcsDuration : this.eConnectDuration
    },
  },
})
