import {ChargerSpeed, Location} from '@/types/app'
import {Option} from '@/components/DSelect.vue'
import {GoogleMapTypes} from '@/models/maps/Map.model'
import {GenericDataset} from '@/models/admin/GenericDataset.model'
import {defineStore} from 'pinia'
import {LocalStorage, LocalStorageKey} from '@/utils/global.utils'
import {
  featureOverlayDisabled,
  getGenericLayerConfiguration,
  layerConfiguration,
} from '@/utils/layers/layerMapping.utils'
import {Organization} from '@/auth/roles'
import {useOrganizationStore} from '@/stores/organization.store'
import {MVTLayerConfiguration} from '@/utils/layers/layout-config'


function getDefaultMapType() {
  return LocalStorage.getItem(LocalStorageKey.GOOGLE_MAP_TYPE, GoogleMapTypes.hybrid) as GoogleMapTypes
}

export type DisplayOption = 'status' | 'all' | string

export interface UIState {
  selectedFeasibilityItems: string[]
  selectedOtherFeasibilityLayers: string[]
  selectedLayer?: Option<string>
  ownChargers: boolean
  eConnectChargers: boolean
  mapType: GoogleMapTypes
  visibleChargers: ChargerSpeed[]
  genericDatasets: GenericDataset[]
  cpoNames: string[]
  parkingOperators: string[]
  parkingCategories: string[];
  // random has used for firing watchers
  mapLastLocation: {bounds: google.maps.LatLngBounds, location: Location} | undefined

  //  if any of the toolbar options are selected
  parkingData: boolean
  mapLocationName: string
  refresh: boolean
  displayOption: DisplayOption
  lsoaEnabled: boolean,
  utilisationShown: boolean,
  zoomWarning: boolean,
  planningLevelZoomEnabled: boolean,
  layersSelected: MVTLayerConfiguration | undefined
}

export type UserInterfaceStore = ReturnType<typeof useUserInterfaceStore>

export const useUserInterfaceStore = defineStore('userInterface', {
  state: (): UIState => ({
    selectedFeasibilityItems: [],
    selectedOtherFeasibilityLayers: [],
    ownChargers: false,
    eConnectChargers: false,
    mapType: getDefaultMapType(),
    visibleChargers: [],
    genericDatasets: [],
    cpoNames: [],
    parkingData: false,
    mapLastLocation: undefined,
    parkingOperators: [],
    parkingCategories: [],
    mapLocationName: '',
    refresh: false,
    displayOption: 'status',
    lsoaEnabled: false,
    utilisationShown: false,
    zoomWarning: false,
    layersSelected: undefined,
    planningLevelZoomEnabled: true,
  }),

  getters: {
    availableGenericDatasets(): GenericDataset[] {
      return this.genericDatasets.filter(d => !d.failed_status)
    },

    cpoNameOptions(): Option[] {
      return this.cpoNames.map(value => ({ value, label: value }))
    },

    layers():MVTLayerConfiguration[] {
      const os = useOrganizationStore()

      const layers = layerConfiguration(this.roles, os.organizationName as Organization).filter((layer) => {
        if (!layer.role) {
          return true
        }

        return this.roles.hasRole(layer.role)
      })

      const getGenericLayers = this.genericLayers
        .map((dataset: GenericDataset) => {
          return getGenericLayerConfiguration(dataset)
        })

      return [...layers, ...getGenericLayers]
    },
 
    genericLayers(): GenericDataset[] {
      return this.availableGenericDatasets.filter((dataset: GenericDataset) => dataset.ui_location === 'LAYER')
    },
  },

  actions: {
    resetDisplayOption(): void {
      this.displayOption = 'status'
    },

    shouldAddFeatureOverlay(name: string) {
      const os = useOrganizationStore()
      const overlay = this.genericLayers.find((dataset: GenericDataset) => dataset.name === name)
      return overlay && !featureOverlayDisabled(this.roles, os.organizationName as Organization, name)
    },

    isGenericLayer(name: string) {
      return this.genericLayers.some((dataset: GenericDataset) => dataset.name === name)
    },

    setMapType(mapType: GoogleMapTypes): void {
      this.mapType = mapType
      LocalStorage.setItem(LocalStorageKey.GOOGLE_MAP_TYPE, mapType)
    },

    setCpoNames(cpoNames: string[]): void {
      this.cpoNames = cpoNames.toSorted((a, b) => a.localeCompare(b))
    },

    setParkingOperators(parkingOperators: string[]): void {
      this.parkingOperators = parkingOperators.sort()
    },

    setParkingCategories(parkingCategories: string[]): void {
      this.parkingCategories = parkingCategories.sort()
    },
    displayChange(value: string) {
      this.displayOption = value
    },
  },
})
