import { ScoreSortType, WorkspaceSite } from '@/types/workspace'
import { ToolboxSite } from '@/libs/workspace'
import { mapOverlayZIndex } from '@/utils/layers/layerStyles.utils'
import {LabelSetsStore} from '@/stores/label-sets.store'

export interface UploadSiteInput {
  label: string
  model: string
}

export const generateInputs = (size: number): UploadSiteInput[] => {
  return Array.from(Array(size))
    .map((_, i) => ({
      label: `Site ${i + 1}`,
      model: '',
    }))
}

export const wardsStyle = {
  fillOpacity: 0,
  strokeColor: '#FFB52E',
  strokeWeight: 2,
  opacity: 0.5,
  zIndex: mapOverlayZIndex.mapWorkspaceWards,
}
function filterBySitesWithScoresAndSitesWithoutScores<T extends WorkspaceSite>(sites: T[]) {
  const sitesWithScore = sites.filter(s => s.properties.aggregate_score !== null) 
  const sitesWithNoneScore = sites.filter(s => s.properties.aggregate_score === null) 
  return [
    sitesWithScore,
    sitesWithNoneScore,
  ]
}

export class SiteSelectionHelpers {

  public static siteScoreSort<T extends WorkspaceSite>(sites: T[], sortType: ScoreSortType, labelSetsStore: LabelSetsStore): T[] {
    const sitesInActiveLabelSet = []
    const otherSites = []

    for (const site of sites) {
      if (labelSetsStore.isSiteInActiveLabelSet(site)) {
        sitesInActiveLabelSet.push(site)
      } else {
        otherSites.push(site)
      }
    }


    switch (sortType) {
      case 'Lowest score first': {
        const sortFn = (a: T, b: T) => {
          return a.properties.aggregate_score - b.properties.aggregate_score
        }
        const sitesInLabelSetFilterByScore = filterBySitesWithScoresAndSitesWithoutScores(sitesInActiveLabelSet) 
        const sitesOtherFilterByScore = filterBySitesWithScoresAndSitesWithoutScores(otherSites) 

        return [
          ...sitesInLabelSetFilterByScore[1].toSorted(sortFn),
          ...sitesInLabelSetFilterByScore[0].toSorted(sortFn),
          ...sitesOtherFilterByScore[1].toSorted(sortFn),
          ...sitesOtherFilterByScore[0].toSorted(sortFn),
        ]
      }

      case 'Highest score first': {
        const sortFn = (a: T, b: T) => {
          return b.properties.aggregate_score - a.properties.aggregate_score
        }

        const sitesInLabelSetFilterByScore = filterBySitesWithScoresAndSitesWithoutScores(sitesInActiveLabelSet) 
        const sitesOtherFilterByScore = filterBySitesWithScoresAndSitesWithoutScores(otherSites) 

        return [
          ...sitesInLabelSetFilterByScore[0].toSorted(sortFn),
          ...sitesInLabelSetFilterByScore[1].toSorted(sortFn),
          ...sitesOtherFilterByScore[0].toSorted(sortFn),
          ...sitesOtherFilterByScore[1].toSorted(sortFn),
        ]
      }

      case 'Oldest first': {
        const sortFn = (a, b) => {
          if (!a.properties.created_at) {
            return 1
          } else if (!b.properties.created_at) {
            return -1
          } else {
            // Extra sorting code added because there is an issue with records which have the same date value
            const sortValue = new Date(b.properties.created_at).getTime() - new Date(a.properties.created_at).getTime() || a.properties.street.localeCompare(b.properties.street)
            return -sortValue
          }
        }
        return [
          ...sitesInActiveLabelSet.toSorted(sortFn),
          ...otherSites.toSorted(sortFn),
        ]
      }

      default: {
        const sortFn = (a, b) => {
          if (!a.properties.created_at) {
            return 1
          } else if (!b.properties.created_at) {
            return -1
          } else {
            // Extra sorting code added because there is an issue with records which have the same date value
            return new Date(b.properties.created_at).getTime() - new Date(a.properties.created_at).getTime() || a.properties.street.localeCompare(b.properties.street)
          }
        }
        return [
          ...sitesInActiveLabelSet.toSorted(sortFn),
          ...otherSites.toSorted(sortFn),
        ]
      }
    }
  }

  public static filterByAddressQuery(site: ToolboxSite, addressQuery: string): boolean {
    if (addressQuery.length === 0) {
      return true
    }

    return site.searchInfo.address.includes(addressQuery)
  }

  public static filterByStatusQuery(site: ToolboxSite, statusQuery: string[]): boolean {
    if (statusQuery.length === 0) {
      return true
    }

    return statusQuery.includes(site.searchInfo.status)
  }

  public static filterByAssessmentsQuery(site: ToolboxSite, assessmentsQuery: string[]): boolean {
    if (assessmentsQuery.length === 0) {
      return true
    }

    return assessmentsQuery.includes(site.searchInfo.assessment_name)
  }

  public static siteMatchesFilters(site: ToolboxSite, addressQuery: string, statusQuery: string[], assessmentsQuery: string[]): boolean {
    return this.filterByAddressQuery(site, addressQuery)
      && this.filterByStatusQuery(site, statusQuery)
      && this.filterByAssessmentsQuery(site, assessmentsQuery)
  }
}

export const siteMarkerLayerTag = 'workspace-site-markers'
export const siteLabelOverlayTag = 'workspace-site-labels'

export interface Site extends WorkspaceSite {
  selected: boolean
  /**
   * Used for fulltext site search
   */
  searchInfo: string
  icon: string
}

export const defaultSiteAssessment = {
  active: true,
  name: '', 
  assessment_name: '',
  description: '',
  planning_level: 2,
  show_generate_sites: false,
  show_infill: false,
  show_constraint_model: false,
  show_on_area_explorer: false,
  enable_circle_results: false,
  enable_heatmap_results: false,
  show_on_workspace_smart_planning_tab: false,
  numerical_presentation_of_model: false,
  constraint_profile: [],
  models: [],
  presentations: [],
}

