import {defineStore} from 'pinia'
import { ComponentModel, InputOptionTypes, OrganizationConfiguration, SiteAssessment, UserData } from '@/libs/auth/types'
import {User} from '@/libs/api/types'
import {Option} from '@/components/DSelect.vue'
import {useAppStore} from '@/stores/app.store'
import {AppRole, Organization} from '@/auth/roles'
import ConnectedKerb from '@/assets/brand/connected-kerb/square_logo.svg'
import ConnectedKerbCollapsed from '@/assets/brand/connected-kerb/logo_collapsed_ck.svg'
import DodonaAnalytics from '@/assets/brand/dodonaanalytics/logo.svg'
import { WorkspaceIndexItem } from '@/types/workspace'
import {RoleManager} from '@/libs/RoleManager'
import { defaultSiteAssessment } from '@/models/SiteSelection/SiteSelection.model'
import {ChargerSpeedMapping, ChargerSpeedScheme, chargerSpeedSchemes} from '@/types/app'


interface OrganizationLogo {
  src: string,
  alt: string
}

export type OrganizationStore = ReturnType<typeof useOrganizationStore>

export const useOrganizationStore = defineStore('organization', {
  state: () => ({
    /**
     * The current active user
     */
    user: {
      email: '',
      display: '',
      organization: '',
    } as UserData,

    /**
     * All users in this organization
     */
    users: [] as User[],
    /**
     * All workspaces in this organization
     */
    workspaces: [] as WorkspaceIndexItem[],

    config: undefined as OrganizationConfiguration | undefined,
    /**
     * Used for the region error component
     */
    configStatus: 'pending' as 'pending' | 'loaded' | 'error',
  }),

  getters: {
    operators(): string[] {
      return this.config?.operators ?? []
    },

    operatorsSet(): Set<string> {
      return new Set<string>(this.operators)
    },

    getSelectedChargerSpeedCategorization(): ChargerSpeedScheme {
      if (!this.config) {
        return 'OZEV'
      }
      return this.config.selected_charger_speed_categorization
    },

    getSelectedChargerSpeedMapping(): ChargerSpeedMapping {
      return chargerSpeedSchemes[this.getSelectedChargerSpeedCategorization]
    },

    organizationName(): string {
      return this.user.organization || ''
    },

    isCk(): boolean {
      return Organization.connectedKerb === this.organizationName || Organization.connectedKerbUs === this.organizationName
    },

    organizationLogoCollapsed(): OrganizationLogo {
      switch (this.organizationName) {
        case Organization.connectedKerbUs:
        case Organization.connectedKerb:
          return {
            src: ConnectedKerbCollapsed,
            alt: 'Connected Kerb',
          }

        default:
          return {
            src: DodonaAnalytics,
            alt: 'Dodona Analytics',
          }
      }
    },

    organizationLogo(): OrganizationLogo {
      switch (this.organizationName) {
        case Organization.connectedKerbUs:
        case Organization.connectedKerb:
          return {
            src: ConnectedKerb,
            alt: 'Connected Kerb',
          }

        default:
          return {
            src: DodonaAnalytics,
            alt: 'Dodona Analytics',
          }
      }
    },

    displayName(): string {
      return this.user.display || this.user.email
    },

    email(): string {
      return this.user.email
    },

    userOptions(): Option[] {
      const userOptions = this.users
        .filter((user) => {
          return user.selectable
        })
        .map((user) => {
          return {
            label: user.name,
            value: user.id,
          }
        })

      return [
        { label: 'None', value: null },
        ...userOptions,
      ]
    },

    activeAssessments(): SiteAssessment[] {
      if (this.config) {
        return this.config.site_assessments.filter(({ active }) => active !== false)
      }

      return []
    },

    allAssessments(): SiteAssessment[] {
      if (this.config) {
        return this.config.site_assessments
      }

      return []
    },

    areaExplorerAssessments(): SiteAssessment[] {
      return this.activeAssessments.filter(({show_on_area_explorer}: SiteAssessment) => {
        return show_on_area_explorer
      })
    },

    hasAreaExplorerAssessments(): boolean {
      return this.areaExplorerAssessments.length > 0
    },

    getSiteAssessment(): (assessmentName: string | null) => Partial<SiteAssessment> {
      return (assessmentName) => {
        const numericalAssessment = {
          numerical_presentation_of_model: true,
          models: [],
        }
        // if id is missing than that means that we are accessing old data which doesn't have assessment_name
        // in this case we show the numeric version which will show - 
        if (!assessmentName) {
          return numericalAssessment 
        }
        const assessment = this.config?.site_assessments.find(assessment => assessment.assessment_name === assessmentName)

        if (assessment) {
          return assessment
        }

        return defaultSiteAssessment
      }
    },


    getModel(): (assessmentName: string, modelName: string) => ComponentModel {
      return (assessmentName: string, modelName: string) => {
        const assessment = this.config?.site_assessments.find(assessment => assessment.assessment_name === assessmentName)

        if (assessment) {
          const model = assessment.models.find(model => model.model_name === modelName)
          if (model) {
            return model
          }
        }

        return {
          display_name: '',
          model_name: '', 
          presentations: [],
        }
      }
    },

    siteAssessmentName(): (assessmentName: string) => string {
      return (assessmentName: string): string => {
        if (!this.config) {
          return ''
        }

        return this.config.site_assessments.find(({assessment_name}) => assessment_name === assessmentName)?.name || ''
      }
    },
  },

  actions: {

    async updateChargerSpeedCategorization(chargerSpeedCategorization: string) {
      await this.api.updateChargerSpeedCategorization(chargerSpeedCategorization)
      this.config!.selected_charger_speed_categorization = chargerSpeedCategorization as ChargerSpeedScheme
    },

    getInputOptionsByProperty<T>(assessmentName: string, property: InputOptionTypes): Option<T>[] {
      // check if property is nested 
      const keywords = property.split('.')
      const prop = keywords[0] as InputOptionTypes
      if (keywords.length === 1) {
        const dictionary = this.config?.site_param_options[assessmentName]
        return dictionary && dictionary[prop]?.choices || []
      }
      const dictionary = this.config?.site_param_options[assessmentName]
      return dictionary && dictionary[prop]?.dynamic_choices[keywords[1]][keywords[2]] || []
    },

    addOrganizationUsers(users: User[]) {
      this.users = users
    },

    addOrganizationWorkspaces(workspaces: WorkspaceIndexItem[]) {
      this.workspaces = workspaces 
    },

    setConfig(config: OrganizationConfiguration) {
      this.config = config
      this.configStatus = 'loaded'

      if (config) {
        useAppStore().location = config.start_location
      }
    },

    hasActiveSiteAssessmentName(assessmentName: string | null): boolean {
      if (assessmentName === null) {
        // In Germany there are no site_assessments. In that case return true
        return true
      }
      const selectedAssessment = this.config?.site_assessments.find((sa) => sa.assessment_name === assessmentName)

      if (selectedAssessment) {
        return selectedAssessment.active
      }

      return false
    },

    isUserLocked(roles: RoleManager, workspace?: { locked: boolean, created_by_email: string }): boolean {
      const { email } = this.user

      if (roles.hasRole(AppRole.workspaceLocking) && workspace) {
        return workspace.locked && workspace.created_by_email !== email
      }

      return false
    },
  },
})
