import {
  ColoringFunctions,
  ColoringModeDatasetData,
  GenericEvForecastMetric,
  GenericMacroPowerForecastMetric,
  MVTLayerConfiguration,
  MapLayerTags,
} from '@/utils/layers/layout-config'
import {AppRole, LayerRole, Organization} from '@/auth/roles'
import {
  clusteringV1Coloring,
  clusteringV2Coloring,
  clusteringV3Coloring,
  offstreetParkingColoring,
  offstreetParkingColoringResidential,
} from '@/utils/layers/layerStyles.utils'
import {Option} from '@/components/DSelect.vue'
import {RoleManager} from '@/libs/RoleManager'
import {GeometryDensity, GeometryType, GeometryZoomLevels} from '@/utils/layers/layout-vector-config'
import {Feature, GeoJsonProperties, Geometry} from 'geojson'


export const trafficDensityLayer = 'Traffic density'

export const forecastPeriod = [
  { label: '2 years', value: '2026' },
  { label: '4 years', value: '2028' },
] as Option[]

export const forecastMetric = [
  // Gets number of vehicles
  { label: 'Predicted range of electrical vehicles', value: 'predicted_range_of_electrical_vehicles' },
  // Gets the ev in percantage
  { label: 'Predicted range of ev uptake', value: 'predicted_range_of_ev_uptake' },
] as Option[]

export type EvForecastMetric = 'predicted_range_of_electrical_vehicles' | 'predicted_range_of_ev_uptake'

export const layersUK = (roleManager: RoleManager, organizationName: Organization):MVTLayerConfiguration[] => {
  const layers: (MVTLayerConfiguration)[] = [
    new MVTLayerConfiguration({
      minZoom: GeometryZoomLevels.normalGeometry,
      name: 'Car ownership density',
      group: 'Demographic data',
      description: 'Average number of cars owned per household',
      dataset: 'DEMOGRAPHY_CAR_OWNERSHIP',
      content_type: {type: GeometryType.polygon, density: GeometryDensity.dense},
      coloring_mode: ColoringModeDatasetData.gradientAlgorithm,
      tag: MapLayerTags.layerTab,
      tooltipProperties: [
        {
          text: 'Cars per household',
          property: 'cars_per_household',
          rounded: 2,
        },
      ],
    }),

    new MVTLayerConfiguration({
      minZoom: GeometryZoomLevels.normalGeometry,
      name: 'Residential property price',
      group: 'Demographic data',
      description: 'Median property price',
      dataset: 'DEMOGRAPHY_PROPERTY_PRICE',
      content_type: {type: GeometryType.polygon, density: GeometryDensity.dense},
      coloring_mode: ColoringModeDatasetData.gradientAlgorithm,
      tag: MapLayerTags.layerTab,
      tooltipProperties: [
        {
          text: 'Median property price',
          property: 'prop_price',
          rounded: 0,
        },
      ],
    }),

    new MVTLayerConfiguration({
      minZoom: GeometryZoomLevels.normalGeometry,
      name: 'Population density',
      group: 'Demographic data',
      description: 'Population count per square kilometer',
      dataset: 'DEMOGRAPHY_POP_DENSITY',
      content_type: {type: GeometryType.polygon, density: GeometryDensity.dense},
      coloring_mode: ColoringModeDatasetData.gradientAlgorithm,
      tag: MapLayerTags.layerTab,
      tooltipProperties: [
        {
          text: 'Population density per square km',
          property: 'pop_density',
          rounded: 0,
        },
      ],
    }),

    new MVTLayerConfiguration({
      minZoom: GeometryZoomLevels.normalGeometry,
      name: 'Household income',
      group: 'Demographic data',
      description: 'Median household income',
      dataset: 'DEMOGRAPHY_INCOME_DATA',
      content_type: {type: GeometryType.polygon, density: GeometryDensity.dense},
      coloring_mode: ColoringModeDatasetData.gradientAlgorithm,
      tag: MapLayerTags.layerTab,
      tooltipProperties: [
        {
          text: 'Median household income',
          property: 'median_income',
          rounded: 0,
        },
      ],
    }),

    new MVTLayerConfiguration({
      minZoom: GeometryZoomLevels.normalGeometry,
      name: 'EV registrations by LSOA (Q4 2022) - Private',
      group: 'Other data',
      dataset: 'EV registrations by LSOA - Private',
      content_type: {type: GeometryType.polygon, density: GeometryDensity.dense},
      coloring_mode: ColoringModeDatasetData.gradientAlgorithm,
      coloring_property: 'value',
      tag: MapLayerTags.layerTab,
      tooltipProperties: [
        {
          text: 'Number of registered private EVs in Q4 of 2022',
          property: 'value',
          rounded: 0,
        },
      ],
    }),
    new MVTLayerConfiguration({
      minZoom: GeometryZoomLevels.normalGeometry,
      name: 'EV registrations by LSOA (Q4 2022) - Company',
      group: 'Other data',
      dataset: 'EV registrations by LSOA - Company',
      content_type: {type: GeometryType.polygon, density: GeometryDensity.dense},
      coloring_mode: ColoringModeDatasetData.gradientAlgorithm,
      coloring_property: 'value',
      tag: MapLayerTags.layerTab,
      hasTooltip: true,
      tooltipProperties: [
        {
          text: 'Number of registered company EVs in Q4 of 2022',
          property: 'value',
          rounded: 0,
        },
      ],
    }),
    new MVTLayerConfiguration({
      minZoom: GeometryZoomLevels.normalGeometry,
      name: 'EV registrations by LSOA (Q3 2023) - Private',
      group: 'Other data',
      dataset: 'EV registrations by LSOA 2024',
      content_type: {type: GeometryType.polygon, density: GeometryDensity.dense},
      coloring_mode: ColoringModeDatasetData.gradientAlgorithm,
      coloring_property: 'latest_private',
      tag: MapLayerTags.layerTab,
      hasTooltip: true,
      tooltipProperties: [
        {
          text: 'Number of registered private EVs in Q3 of 2023',
          property: 'latest_private',
          rounded: 0,
        },
      ],
    }),

    new MVTLayerConfiguration({
      minZoom: GeometryZoomLevels.normalGeometry,
      name: 'EV registrations by LSOA (Q3 2023) - Company',
      group: 'Other data',
      dataset: 'EV registrations by LSOA 2024',
      content_type: {type: GeometryType.polygon, density: GeometryDensity.dense},
      coloring_mode: ColoringModeDatasetData.gradientAlgorithm,
      coloring_property: 'latest_company',
      tag: MapLayerTags.layerTab,
      hasTooltip: true,
      tooltipProperties: [
        {
          text: 'Number of registered company EVs in Q3 of 2023',
          property: 'latest_company',
          rounded: 0,
        },
      ],
    }),

    new MVTLayerConfiguration({
      minZoom: GeometryZoomLevels.normalGeometry,
      name: 'EV registrations by LSOA (Q1 2024) - Private',
      group: 'Other data',
      dataset: 'Vehicle registrations by LSOA 2024 (September)',
      content_type: {type: GeometryType.polygon, density: GeometryDensity.dense},
      coloring_mode: ColoringModeDatasetData.gradientAlgorithm,
      coloring_property: 'ev_latest_private',
      tag: MapLayerTags.layerTab,
      hasTooltip: true,
      tooltipProperties: [
        {
          text: 'Number of registered private EVs in Q1 of 2024',
          property: 'ev_latest_private',
          rounded: 0,
        },
      ],
    }),

    new MVTLayerConfiguration({
      minZoom: GeometryZoomLevels.normalGeometry,
      name: 'EV registrations by LSOA (Q1 2024) - Company',
      group: 'Other data',
      dataset: 'Vehicle registrations by LSOA 2024 (September)',
      content_type: {type: GeometryType.polygon, density: GeometryDensity.dense},
      coloring_mode: ColoringModeDatasetData.colorFunction,
      color: new ColoringFunctions(
        (feature: Feature<Geometry, GeoJsonProperties>): [number, number, number, number] => {
          return evRegistrationCompanyColorFunction(feature.properties?.ev_latest_company ?? 0, 120)
        },
        (feature: Feature<Geometry, GeoJsonProperties>): [number, number, number, number] => {
          return evRegistrationCompanyColorFunction(feature.properties?.ev_latest_company ?? 0, 255)
        },
      ),
      mapKeyOverride: [
        {color: ['#440154A0','#46015EA0','#48116BA0'], titleLeft: '1', titleRight: '3'},
        {color: ['#432A72A0','#3E3C79A0','#384E80A0'], titleLeft: '3', titleRight: '6'},
        {color: ['#326087A0','#2D708EA0','#2A7E8EA0'], titleLeft: '6', titleRight: '10'},
        {color: ['#268C8DA0','#229A8CA0','#26A784A0'], titleLeft: '10', titleRight: '18'},
        {color: ['#3DBA74A0','#5FD260A0','#85DE4BA0'], titleLeft: '18', titleRight: '100'},
        {color: ['#B3E632A0','#D6EC20A0','#FDE725A0'], titleLeft: '100', titleRight: '129,012'},
      ],
      tag: MapLayerTags.layerTab,
      hasTooltip: true,
      tooltipProperties: [
        {
          text: 'Number of registered company EVs in Q1 of 2024',
          property: 'ev_latest_company',
          rounded: 0,
        },
      ],
    }),

    new MVTLayerConfiguration({
      minZoom: GeometryZoomLevels.normalGeometry,
      name: 'EV registrations by LSOA (Q2 2024) - Private',
      group: 'Other data',
      dataset: 'VEH_REG_LSOA',
      version: '2024_Q2',
      content_type: {type: GeometryType.polygon, density: GeometryDensity.dense},
      coloring_mode: ColoringModeDatasetData.gradientAlgorithm,
      coloring_property: 'ev_latest_private',
      tag: MapLayerTags.layerTab,
      hasTooltip: true,
      tooltipProperties: [
        {
          text: 'Number of registered private EVs in Q2 of 2024',
          property: 'ev_latest_private',
          rounded: 0,
        },
      ],
    }),

    new MVTLayerConfiguration({
      minZoom: GeometryZoomLevels.normalGeometry,
      name: 'EV registrations by LSOA (Q2 2024) - Company',
      group: 'Other data',
      dataset: 'VEH_REG_LSOA',
      version: '2024_Q2',
      content_type: {type: GeometryType.polygon, density: GeometryDensity.dense},
      coloring_mode: ColoringModeDatasetData.colorFunction,
      color: new ColoringFunctions(
        (feature: Feature<Geometry, GeoJsonProperties>): [number, number, number, number] => {
          return evRegistrationCompanyColorFunction(feature.properties?.ev_latest_company ?? 0, 120)
        },
        (feature: Feature<Geometry, GeoJsonProperties>): [number, number, number, number] => {
          return evRegistrationCompanyColorFunction(feature.properties?.ev_latest_company ?? 0, 255)
        },
      ),
      mapKeyOverride: [
        {color: ['#440154A0','#46015EA0','#48116BA0'], titleLeft: '1', titleRight: '3'},
        {color: ['#432A72A0','#3E3C79A0','#384E80A0'], titleLeft: '3', titleRight: '6'},
        {color: ['#326087A0','#2D708EA0','#2A7E8EA0'], titleLeft: '6', titleRight: '10'},
        {color: ['#268C8DA0','#229A8CA0','#26A784A0'], titleLeft: '10', titleRight: '18'},
        {color: ['#3DBA74A0','#5FD260A0','#85DE4BA0'], titleLeft: '18', titleRight: '100'},
        {color: ['#B3E632A0','#D6EC20A0','#FDE725A0'], titleLeft: '100', titleRight: '129,012'},
      ],
      tag: MapLayerTags.layerTab,
      hasTooltip: true,
      tooltipProperties: [
        {
          text: 'Number of registered company EVs in Q2 of 2024',
          property: 'ev_latest_company',
          rounded: 0,
        },
      ],
    }),

    new MVTLayerConfiguration({
      minZoom: GeometryZoomLevels.normalGeometry,
      name: 'All vehicles registrations by LSOA (Q3 2023) - Private',
      group: 'Other data',
      dataset: 'Vehicle registrations by LSOA 2024',
      content_type: {type: GeometryType.polygon, density: GeometryDensity.dense},
      coloring_mode: ColoringModeDatasetData.gradientAlgorithm,
      coloring_property: 'all_cars_latest_private',
      tag: MapLayerTags.layerTab,
      hasTooltip: true,
      tooltipProperties: [
        {
          text: 'Number of registered private vehicles in Q3 of 2023',
          property: 'all_cars_latest_private',
          rounded: 0,
        },
      ],
    }),

    new MVTLayerConfiguration({
      minZoom: GeometryZoomLevels.normalGeometry,
      name: 'All vehicles registrations by LSOA (Q3 2023) - Company',
      group: 'Other data',
      dataset: 'Vehicle registrations by LSOA 2024',
      content_type: {type: GeometryType.polygon, density: GeometryDensity.dense},
      coloring_mode: ColoringModeDatasetData.gradientAlgorithm,
      coloring_property: 'all_cars_latest_company',
      tag: MapLayerTags.layerTab,
      hasTooltip: true,
      tooltipProperties: [
        {
          text: 'Number of registered company vehicles in Q3 of 2023',
          property: 'all_cars_latest_company',
          rounded: 0,
        },
      ],
    }),

    new MVTLayerConfiguration({
      minZoom: GeometryZoomLevels.normalGeometry,
      name: 'All vehicles registrations by LSOA (Q1 2024) - Private',
      group: 'Other data',
      dataset: 'Vehicle registrations by LSOA 2024 (September)',
      content_type: {type: GeometryType.polygon, density: GeometryDensity.dense},
      coloring_mode: ColoringModeDatasetData.gradientAlgorithm,
      coloring_property: 'all_cars_latest_private',
      tag: MapLayerTags.layerTab,
      hasTooltip: true,
      tooltipProperties: [
        {
          text: 'Number of registered private vehicles in Q1 of 2024',
          property: 'all_cars_latest_private',
          rounded: 0,
        },
      ],
    }),

    new MVTLayerConfiguration({
      minZoom: GeometryZoomLevels.normalGeometry,
      name: 'All vehicles registrations by LSOA (Q1 2024) - Company',
      group: 'Other data',
      dataset: 'Vehicle registrations by LSOA 2024 (September)',
      content_type: {type: GeometryType.polygon, density: GeometryDensity.dense},
      coloring_mode: ColoringModeDatasetData.gradientAlgorithm,
      coloring_property: 'all_cars_latest_company',
      tag: MapLayerTags.layerTab,
      hasTooltip: true,
      tooltipProperties: [
        {
          text: 'Number of registered company vehicles in Q1 of 2024',
          property: 'all_cars_latest_company',
          rounded: 0,
        },
      ],
    }),

    new MVTLayerConfiguration({
      minZoom: GeometryZoomLevels.normalGeometry,
      name: 'Total car registrations by LSOA (Q2 2024) - Private',
      group: 'Other data',
      dataset: 'VEH_REG_LSOA',
      version: '2024_Q2',
      content_type: {type: GeometryType.polygon, density: GeometryDensity.dense},
      coloring_mode: ColoringModeDatasetData.gradientAlgorithm,
      coloring_property: 'all_cars_latest_private',
      tag: MapLayerTags.layerTab,
      hasTooltip: true,
      tooltipProperties: [
        {
          text: 'Number of registered private vehicles in Q2 of 2024',
          property: 'all_cars_latest_private',
          rounded: 0,
        },
      ],
    }),

    new MVTLayerConfiguration({
      minZoom: GeometryZoomLevels.normalGeometry,
      name: 'Total car registrations by LSOA (Q2 2024) - Company',
      group: 'Other data',
      dataset: 'VEH_REG_LSOA',
      version: '2024_Q2',
      content_type: {type: GeometryType.polygon, density: GeometryDensity.dense},
      coloring_mode: ColoringModeDatasetData.gradientAlgorithm,
      coloring_property: 'all_cars_latest_company',
      tag: MapLayerTags.layerTab,
      hasTooltip: true,
      tooltipProperties: [
        {
          text: 'Number of registered company vehicles in Q2 of 2024',
          property: 'all_cars_latest_company',
          rounded: 0,
        },
      ],
    }),

    new MVTLayerConfiguration({
      minZoom: GeometryZoomLevels.normalGeometry,
      role: LayerRole.evCountByLa,
      name: 'EV Count by LA (2021)',
      group: 'Other data',
      description: 'Total number of registered electric vehicles by local authority 2021',
      dataset: 'EV_COUNT',
      content_type: {type: GeometryType.polygon, density: GeometryDensity.dense},
      coloring_mode: ColoringModeDatasetData.gradientAlgorithm,
      coloring_property: 'total',
      tag: MapLayerTags.layerTab,
      hasTooltip: true,
      tooltipProperties: [
        {
          text: 'Number of registered EVs',
          property: 'total',
          rounded: 0,
        },
      ],
    }),


    new MVTLayerConfiguration({
      minZoom: GeometryZoomLevels.normalGeometry,
      role: LayerRole.predictedOffstreetParkingResidential,
      name: 'Predicted offstreet parking in residential areas, UK',
      group: 'Other data',
      description: 'Offstreet parking in residential areas, UK',
      dataset: 'Predicted offstreet parking in residential areas, UK',
      tag: MapLayerTags.layerTab,
      content_type: {type: GeometryType.polygon, density: GeometryDensity.dense},
      coloring_mode: ColoringModeDatasetData.colorFunction,
      color: offstreetParkingColoringResidential,
      hasTooltip: false,
      mapKeyOverride: [
        { color: ['#00FF00'], titleRight: 'Low probability' },
        { color: ['#FCE77D'], titleRight: 'Mixed' },
        { color: ['#FA0000'], titleRight: 'High probability' },
      ],
    }),

    new MVTLayerConfiguration({
      minZoom: GeometryZoomLevels.normalGeometry,
      name: 'Population age',
      group: 'Demographic data',
      description: 'Median population age',
      dataset: 'DEMOGRAPHY_POPULATION_MEDIAN_AGE',
      content_type: {type: GeometryType.polygon, density: GeometryDensity.dense},
      coloring_mode: ColoringModeDatasetData.gradientAlgorithm,
      tag: MapLayerTags.layerTab,
      tooltipProperties: [
        {
          text: 'Median population age',
          property: 'median_age',
          rounded: 0,
        },
      ],
    }),

    new MVTLayerConfiguration({
      role: LayerRole.householdDensity,
      minZoom: GeometryZoomLevels.normalGeometry,
      name: 'Household density',
      group: 'Demographic data',
      description: 'Number of households per square km',
      dataset: 'DEMOGRAPHY_HOUSEHOLD_DENSITY',
      content_type: {type: GeometryType.polygon, density: GeometryDensity.dense},
      coloring_mode: ColoringModeDatasetData.gradientAlgorithm,
      tag: MapLayerTags.layerTab,
      tooltipProperties: [
        {
          text: 'Households per square km',
          property: 'household_density',
          rounded: 0,
        },
      ],
    }),

    new MVTLayerConfiguration({
      role: LayerRole.pctFlat,
      minZoom: GeometryZoomLevels.normalGeometry,
      name: 'Flats percent',
      group: 'Demographic data',
      description: 'Percentage of households living in flats',
      dataset: 'DEMOGRAPHY_PCT_FLAT',
      content_type: {type: GeometryType.polygon, density: GeometryDensity.dense},
      coloring_mode: ColoringModeDatasetData.gradientAlgorithm,
      tag: MapLayerTags.layerTab,
      tooltipProperties: [
        {
          text: '% of households in flats',
          property: 'pct_flat',
          rounded: 0,
        },
      ],
    }),

    new MVTLayerConfiguration({
      role: LayerRole.pctDetached,
      minZoom: GeometryZoomLevels.normalGeometry,
      name: 'Detached houses percent',
      group: 'Demographic data',
      description: 'Percentage of households living in detached houses',
      dataset: 'DEMOGRAPHY_PCT_DETACHED',
      content_type: {type: GeometryType.polygon, density: GeometryDensity.dense},
      coloring_mode: ColoringModeDatasetData.gradientAlgorithm,
      tag: MapLayerTags.layerTab,
      tooltipProperties: [
        {
          text: '% of households in detached houses',
          property: 'pct_detached',
          rounded: 0,
        },
      ],
    }),

    new MVTLayerConfiguration({
      role: LayerRole.pctSemiDetached,
      minZoom: GeometryZoomLevels.denseGeometry,
      name: 'Semi-detached houses percent',
      group: 'Demographic data',
      description: 'Percentage of households living in semi-detached houses',
      dataset: 'DEMOGRAPHY_PCT_SEMI_DETACHED',
      content_type: {type: GeometryType.polygon, density: GeometryDensity.dense},
      coloring_mode: ColoringModeDatasetData.gradientAlgorithm,
      tag: MapLayerTags.layerTab,
      tooltipProperties: [
        {
          text: '% of households in semi-detached houses',
          property: 'pct_semi_detached',
          rounded: 0,
        },
      ],
    }),

    new MVTLayerConfiguration({
      role: LayerRole.pctTerraced,
      minZoom: GeometryZoomLevels.denseGeometry,
      name: 'Terraced houses percent',
      group: 'Demographic data',
      description: 'Percentage of households living in terraced houses',
      dataset: 'DEMOGRAPHY_PCT_TERRACED',
      content_type: {type: GeometryType.polygon, density: GeometryDensity.dense},
      coloring_mode: ColoringModeDatasetData.gradientAlgorithm,
      tag: MapLayerTags.layerTab,
      tooltipProperties: [
        {
          text: '% of households in terraced houses',
          property: 'pct_terraced',
          rounded: 0,
        },
      ],
    }),

    new MVTLayerConfiguration({
      role: LayerRole.trafficDensity,
      name: trafficDensityLayer,
      group: 'Traffic data',
      description: 'Display daily average traffic density.',
      minZoom: GeometryZoomLevels.denseGeometry,
      dataset: 'TRAFFIC_DENSITY',
      tag: MapLayerTags.layerTab,
      content_type: {type: GeometryType.line, density: GeometryDensity.dense},
      coloring_mode: ColoringModeDatasetData.colorFunction,
      color: new ColoringFunctions(
        [0,0,0,0],
        (feature: Feature<Geometry, GeoJsonProperties>): [number, number, number, number] => {
          const propertyValue = feature.properties?.w || 0
          if (propertyValue <= 1000) {
            return [244, 233, 154, 255]
          } else if (propertyValue > 1000 && propertyValue <= 5000) {
            return [215, 174, 101, 255]
          } else if (propertyValue > 5000 && propertyValue <= 10000) {
            return [194, 122, 108, 255]
          } else if (propertyValue > 10000 && propertyValue <= 15000) {
            return [228, 149, 120, 255]
          } else if (propertyValue > 15000 && propertyValue <= 25000) {
            return [156, 108, 221, 255]
          } else {
            return [32, 99, 242, 255]
          }
        },
      ),
      mapKeyOverride: [
        { color: ['#F4E99A'], titleLeft: '0', titleRight: '1000' },
        { color: ['#D7AE65'], titleLeft: '1000', titleRight: '5000' },
        { color: ['#C27A6C'], titleLeft: '5000', titleRight: '10000' },
        { color: ['#E49578'], titleLeft: '10000', titleRight: '15000' },
        { color: ['#9C6CDD'], titleLeft: '15000', titleRight: '25000' },
        { color: ['#2063F2'], titleLeft: '25000', titleRight: '88155.8' },
      ],
      tooltipProperties: [
        {
          property: 'w',
          text: 'Whole week daily average',
          rounded: 0,
        },
        {
          property: 'we',
          text: 'Weekend daily average',
          rounded: 0,
        },
        {
          property: 'wd',
          text: 'Weekday daily average',
          rounded: 0,
        },
      ],
    }),

    new MVTLayerConfiguration({
      role: LayerRole.predictedOffstreetParkingv1,
      name: 'Predicted offstreet parking - v1',
      group: 'Other data',
      description: 'Model predictions about off-street parking',
      dataset: 'OFFSTREET_PARKING',
      version: 'v1',
      tag: MapLayerTags.layerTab,
      content_type: {type: GeometryType.polygon, density: GeometryDensity.dense},
      coloring_mode: ColoringModeDatasetData.colorFunction,
      minZoom: GeometryZoomLevels.denseGeometry,
      hasTooltip: false,
      mapKeyOverride: [
        { color: ['#83ADBf'], titleRight: 'Non residential' },
        { color: ['#00FF00'], titleRight: 'Low probability' },
        { color: ['#FA0000'], titleRight: 'High probability' },
      ],
      color: offstreetParkingColoring,
    }),

    new MVTLayerConfiguration({
      role: LayerRole.predictedOffstreetParkingv211b,
      name: 'Predicted offstreet parking - v2.1.1 by LSOA',
      group: 'Other data',
      description: 'Model predictions about off-street parking',
      dataset: 'OFFSTREET_PARKING',
      version: 'v2.1.1b',
      tag: MapLayerTags.layerTab,
      content_type: {type: GeometryType.polygon, density: GeometryDensity.dense},
      coloring_mode: ColoringModeDatasetData.colorFunction,
      minZoom: GeometryZoomLevels.denseGeometry,
      hasTooltip: false,
      mapKeyOverride: [
        { color: ['#83ADBf'], titleRight: 'Mainly non residential area' },
        { color: ['#00FF00'], titleRight: 'Low probability' },
        { color: ['#FCE77D'], titleRight: 'Mixed' },
        { color: ['#FA0000'], titleRight: 'High probability' },
      ],
      color: offstreetParkingColoring,
    }),
    new MVTLayerConfiguration({
      role: LayerRole.predictedOffstreetParkingv211,
      name: 'Predicted offstreet parking - v2.1.1',
      group: 'Other data',
      description: 'Model predictions about off-street parking',
      dataset: 'OFFSTREET_PARKING',
      version: 'v2.1.1',
      tag: MapLayerTags.layerTab,
      content_type: {type: GeometryType.polygon, density: GeometryDensity.dense},
      coloring_mode: ColoringModeDatasetData.colorFunction,
      minZoom: GeometryZoomLevels.denseGeometry,
      hasTooltip: false,
      mapKeyOverride: [
        { color: ['#83ADBf'], titleRight: 'Non residential' },
        { color: ['#00FF00'], titleRight: 'Low probability' },
        { color: ['#FA0000'], titleRight: 'High probability' },
      ],
      color: offstreetParkingColoring,
    }),

    new MVTLayerConfiguration({
      role: LayerRole.poiClusteringV1,
      minZoom: GeometryZoomLevels.sparseGeometry,
      name: 'Poi Clusters v1',
      group: 'Other data',
      description: 'Clusters of POI grouped by similar characteristics',
      dataset: 'POI_CLUSTERS',
      version: 'v1',
      tag: MapLayerTags.layerTab,
      content_type: {type: GeometryType.polygon, density: GeometryDensity.sparse},
      coloring_mode: ColoringModeDatasetData.colorFunction,
      color: clusteringV1Coloring,
      hasTooltip: false,
      mapKeyOverride: [
        { color: ['#FF8C00'], titleRight: 'Attractions' },
        { color: ['#20B2AA'], titleRight: 'Accommodation: hotels, resorts' },
        { color: ['#9370DB'], titleRight: 'Mixed: significant parking, outdoor, family friendly, not so shop oriented' },
        { color: ['#AF0397'], titleRight: 'Mixed: shop oriented, food & drink, everyday' },
        { color: ['#FF69B4'], titleRight: 'Mixed: food & drink, nightlife, community oriented, everyday' },
        { color: ['#228B22'], titleRight: 'Accommodation: nature, adventure' },
        { color: ['#FFD700'], titleRight: 'Culture, historic, attraction' },
        { color: ['#32CD32'], titleRight: 'Health' },
        { color: ['#FF4500'], titleRight: 'Sport' },
        { color: ['#1E90FF'], titleRight: 'Education' },
      ],
    }),

    new MVTLayerConfiguration({
      role: LayerRole.poiClusteringV2,
      minZoom: GeometryZoomLevels.sparseGeometry,
      name: 'Poi Clusters v2',
      group: 'Other data',
      description: 'Clusters of POI grouped by similar characteristics; introducing POI area',
      dataset: 'POI_CLUSTERS',
      version: 'v2',
      tag: MapLayerTags.layerTab,
      content_type: {type: GeometryType.polygon, density: GeometryDensity.sparse},
      coloring_mode: ColoringModeDatasetData.colorFunction,
      color: clusteringV2Coloring,
      mapKeyOverride: [
        { color: ['#FF8C00'], titleRight: 'Attraction' },
        { color: ['#9370DB'], titleRight: 'Mixed: not so shop oriented, with parking, nightlife, accommodation' },
        { color: ['#20B2AA'], titleRight: 'Mixed: shops, retail, town centres' },
        { color: ['#228B22'], titleRight: 'Mixed: residential, small shops and amenities' },
        { color: ['#AF0397'], titleRight: 'Accommodation' },
        { color: ['#FFD700'], titleRight: 'Culture, historic, attraction' },
        { color: ['#32CD32'], titleRight: 'Health' },
        { color: ['#FF4500'], titleRight: 'Sport' },
        { color: ['#1E90FF'], titleRight: 'Education' },
      ],
    }),

    new MVTLayerConfiguration({
      role: LayerRole.poiClusteringV3,
      minZoom: GeometryZoomLevels.sparseGeometry,
      name: 'Poi Clusters v3',
      group: 'Other data',
      description: 'Clusters of POI grouped by similar characteristics; introducing NLP model',
      dataset: 'POI_CLUSTERS',
      version: 'v3',
      tag: MapLayerTags.layerTab,
      content_type: {type: GeometryType.polygon, density: GeometryDensity.sparse},
      coloring_mode: ColoringModeDatasetData.colorFunction,
      color: clusteringV3Coloring,
      mapKeyOverride: [
        { color: ['#FFD700'], titleRight: 'Culture' },
        { color: ['#32CD32'], titleRight: 'Health' },
        { color: ['#FF4500'], titleRight: 'Sport' },
        { color: ['#AF0397'], titleRight: 'Accommodation' },
        { color: ['#9370DB'], titleRight: 'Leisure' },
        { color: ['#FF8C00'], titleRight: 'Fun' },
        { color: ['#20B2AA'], titleRight: 'Supermarket with Services' },
        { color: ['#228B22'], titleRight: 'Shops and Amenities' },
        { color: ['#1E90FF'], titleRight: 'Education' },
        { color: ['#571FFF'], titleRight: 'Higher Education' },
        { color: ['#43E6D2'], titleRight: 'Transport Node' },
        { color: ['#43E671'], titleRight: 'Service Station' },
        { color: ['#000000'], titleRight: 'Mixed' },
      ],
    }),

    new MVTLayerConfiguration({
      role: LayerRole.lastMileDepotZones,
      minZoom: GeometryZoomLevels.sparseGeometry,
      name: 'Last Mile Depot Zones',
      description: 'Industrial zones where logistics companies have their depots',
      group: 'Other data',
      dataset: 'LAST_MILE_DEPOT_ZONES',
      content_type: {type: GeometryType.polygon, density: GeometryDensity.dense},
      tag: MapLayerTags.layerTab,
      coloring_mode: ColoringModeDatasetData.colorFunction,
      color:  new ColoringFunctions(
        [0,0,0,0],
        [255,0,0,230],
        undefined,
        3,
      ),
      tooltipProperties: [{ text: 'Brands:', property: 'brands' }],
    }),

    new MVTLayerConfiguration({
      role: LayerRole.localAuthorityLayer,
      minZoom: GeometryZoomLevels.sparseGeometry,
      name: 'Local authority districts',
      description: 'Show local authority districts',
      group: 'Other data',
      dataset: 'LA_2021_BORDERS',
      content_type: {type: GeometryType.polygon, density: GeometryDensity.dense},
      tag: MapLayerTags.layerTab,
      coloring_mode: ColoringModeDatasetData.colorFunction,
      color:  new ColoringFunctions(
        [0,0,0,0],
        [0,178,255,230],
        undefined,
        3,
      ),
      tooltipProperties: [{ text: 'District name:', property: 'name' }],
    }),

    new MVTLayerConfiguration({
      role: LayerRole.onStreetHouseholdPercentage,
      minZoom: GeometryZoomLevels.normalGeometry,
      name: 'London specific % of on-street households',
      group: 'Other data',
      dataset: 'ON_STREET_HOUSEHOLD_PCT',
      content_type: {type: GeometryType.polygon, density: GeometryDensity.dense},
      tag: MapLayerTags.layerTab,
      coloring_mode: ColoringModeDatasetData.gradientAlgorithm,
      coloring_property: 'on_street_households_perc_london_trojan',
      tooltipProperties: [{
        text: 'Forecasted on-street household %',
        property: 'on_street_households_perc_london_trojan',
        rounded: 0,
      }],
    }),

    new MVTLayerConfiguration({
      role: LayerRole.onStreetHouseholdPercentage,
      minZoom: GeometryZoomLevels.normalGeometry,
      name: 'General % of on-street households',
      group: 'Other data',
      dataset: 'ON_STREET_HOUSEHOLD_PCT',
      content_type: {type: GeometryType.polygon, density: GeometryDensity.dense},
      tag: MapLayerTags.layerTab,
      coloring_mode: ColoringModeDatasetData.gradientAlgorithm,
      coloring_property: 'on_street_households_perc_general_trojan',
      tooltipProperties: [{
        text: 'Forecasted on-street household %',
        property: 'on_street_households_perc_general_trojan',
        rounded: 0,
      }],
    }),
  ]

  if (roleManager.hasRole(AppRole.evRegistrationForecast)) {
    layers.push(new GenericEvForecastMetric({
      role: AppRole.evRegistrationForecast,
      minZoom: GeometryZoomLevels.normalGeometry,
      name: 'EV forecast prediction',
      group: 'Demographic data',
      dataset: 'EV_FORECAST',
      content_type: {type: GeometryType.polygon, density: GeometryDensity.dense},
      tag: MapLayerTags.layerTab,
      customTooltipComponent: 'EvForecastTooltip',
      hasTooltip: true,
      coloring_mode: ColoringModeDatasetData.gradientAlgorithm,
      coloring_property: 'baseline_2026_Q3',
      filterProperties: [{
        property_name: 'confidence_category',
        filter: ['high_confidence'],
      }],
    }))
  }


  if (roleManager.hasRole(AppRole.macroPowerForecast)) {
    layers.push(new GenericMacroPowerForecastMetric({
      role: AppRole.macroPowerForecast,
      minZoom: GeometryZoomLevels.normalGeometry,
      name: 'Macro power forecast',
      group: 'Demographic data',
      dataset: 'POWER_FORECAST',
      content_type: {type: GeometryType.polygon, density: GeometryDensity.dense},
      tag: MapLayerTags.layerTab,
      coloring_mode: ColoringModeDatasetData.gradientAlgorithm,
      customTooltipComponent: 'MacroForecastTooltip',
      hasTooltip: true,
      filterProperties: [{
        property_name: 'confidence',
        filter: ['high'],
      }],
    }))
  }


  if (organizationName === Organization.urbanFox) {
    const getColorWithAlpha = (layer: string, alpha: number): [number, number, number, number] => {
      switch (layer) {
        case 'A':
          return [237, 132, 12, alpha]
        case 'B':
          return [12, 136, 237, alpha]
        default:
          return [0, 0, 0, 255]
      }
    }

    layers.push(new MVTLayerConfiguration({
      minZoom: GeometryZoomLevels.sparseGeometry,
      name: 'Plymouth LSOAs',
      group: 'Other data',
      dataset: 'URBAN_FOX_PLYMOUTH_LSOAS',
      content_type: {type: GeometryType.polygon, density: GeometryDensity.sparse},
      tag: MapLayerTags.layerTab,
      coloring_mode: ColoringModeDatasetData.colorFunction,
      color: new ColoringFunctions(
        (feature: Feature<Geometry, GeoJsonProperties>): [number, number, number, number] => {
          const layer = feature.properties?.plymouth_l
          return getColorWithAlpha(layer, 80)
        },
        (feature: Feature<Geometry, GeoJsonProperties>): [number, number, number, number] => {
          const layer = feature.properties?.plymouth_l
          return getColorWithAlpha(layer, 160)
        },
      ),
      tooltipProperties: [
        {
          text: 'LSOA code',
          property: 'lsoa_code',
        },
      ],
    }))
  }

  return layers
}

function evRegistrationCompanyColorFunction(propertyValue: number, alpha: number): [number, number, number, number] {
  if (propertyValue >= 0 && propertyValue < 1) {
    return [68, 1, 84, alpha]
  } else if (propertyValue >= 1 && propertyValue < 2) {
    return [72, 23, 105, alpha]
  } else if (propertyValue >= 2 && propertyValue < 3) {
    return [71, 42, 122, alpha]
  } else if (propertyValue >= 3 && propertyValue < 4) {
    return [67, 61, 132, alpha]
  } else if (propertyValue >= 4 && propertyValue < 5) {
    return [61, 78, 138, alpha]
  } else if (propertyValue >= 5 && propertyValue < 6) {
    return [53, 94, 141, alpha]
  } else if (propertyValue >= 6 && propertyValue < 7) {
    return [46, 109, 142, alpha]
  } else if (propertyValue >= 7 && propertyValue < 8) {
    return [41, 123, 142, alpha]
  } else if (propertyValue >= 8 && propertyValue < 10) {
    return [35, 137, 142, alpha]
  } else if (propertyValue >= 10 && propertyValue < 12) {
    return [31, 151, 139, alpha]
  } else if (propertyValue >= 12 && propertyValue < 15) {
    return [33, 165, 133, alpha]
  } else if (propertyValue >= 15 && propertyValue < 18) {
    return [46, 179, 124, alpha]
  } else if (propertyValue >= 18 && propertyValue < 20) {
    return [70, 192, 111, alpha]
  } else if (propertyValue >= 20 && propertyValue < 30) {
    return [101, 203, 94, alpha]
  } else if (propertyValue >= 30 && propertyValue < 50) {
    return [137, 213, 72, alpha]
  } else if (propertyValue >= 50 && propertyValue < 100) {
    return [176, 221, 47, alpha]
  } else if (propertyValue >= 100 && propertyValue < 250) {
    return [216, 226, 25, alpha]
  } else if (propertyValue >= 250 && propertyValue < 129012) {
    return [253, 231, 37, alpha]
  }
  return [0, 0, 0, alpha]
}
