import {MapLayerTags, MVTLayerConfiguration} from '@/utils/layers/layout-config'
import {CountryCode} from '@/types/app'
import {layersUK} from '@/utils/layers/layers-uk'
import {layersUS} from '@/utils/layers/layers-us'
import GlobalUtils from '@/utils/global.utils'
import {layersDE} from '@/utils/layers/layers-de'
import {RoleManager} from '@/libs/RoleManager'
import {Organization} from '@/auth/roles'
import {DEFAULT_DATASET_COLOR, GenericDataset} from '@/models/admin/GenericDataset.model'
import {GeometryDensity, getPresentableGeometryType} from '@/utils/layers/layout-vector-config'
import {TextFormatUtils} from '@/utils/text.utils'

export const featureOverlayDisabled = (organizationStore: RoleManager, organizationName: Organization, dataset: string): boolean => {
  switch (GlobalUtils.getAppCountry()) {
    case CountryCode.US:
      return layersUS.some(layers => layers.dataset === dataset) 
    case CountryCode.DE:
      return layersDE.some(layers => layers.dataset === dataset) 
    case CountryCode.UK:
      return layersUK(organizationStore, organizationName).some(layers => layers.dataset === dataset)  
    default:
      return false 
  }
}

export function layerConfiguration(organizationStore: RoleManager, organizationName: Organization):MVTLayerConfiguration[] {
  switch (GlobalUtils.getAppCountry()) {
    case CountryCode.US:
      return layersUS
    case CountryCode.DE:
      return layersDE
    case CountryCode.UK:
      return layersUK(organizationStore, organizationName)
    default:
      return []
  }
}

export function getGenericLayerConfiguration(g: GenericDataset): MVTLayerConfiguration {
  const tooltipProperties = (g.tooltip_properties || []).filter(p => p.visible)
  return new MVTLayerConfiguration({
    name: g.name,
    dataset: g.name,
    color: g.rendering_base_color ?? DEFAULT_DATASET_COLOR,
    label: g.icon_label,
    tag: g.ui_location === 'POI' ? MapLayerTags.poiTab : MapLayerTags.layerTab,
    content_type: {type: getPresentableGeometryType(g.content_type), density: GeometryDensity.sparse},
    coloring_mode: g.coloring_mode,
    iconPropertyShownBelow: g.icon_property_shown_below,
    minZoom: g.min_zoom,
    includeLabel: true,
    hasTooltip: tooltipProperties.length > 0,
    tooltipProperties: tooltipProperties.map(p => ({text: TextFormatUtils.convertToHumanReadable(p.property), property: p.property})),
  })
}

export const parkingMaxZoom = 9

