import { Option } from '@/components/DSelect.vue'
import Konva from 'konva'
import { SiteScoreDetail} from '@/types/workspace'
import {Feature, Point} from 'geojson'
import {ScoreColorPresentation, ScoreMissingReason, SiteAssessmentScore} from '@/models/SiteSelection/SiteScore.model'
import {ChargerPoint} from '@/models/admin/Configuration.model'

export interface SiteCapturingResponse {
  id: number
  name: string, 
  map_type: string, 
  zoom: number,
  rotate: number,
  tilt: number,
  position: number,
  was_moved: boolean,
  coordinates: [number, number],
  polygons?: {
    rectangles: Konva.RectConfig[],
    polylines: Konva.LineConfig[],
    text_items: Konva.TextConfig[],
    image_items: Konva.ImageConfig[],
    image_items_text: Konva.TextConfig[]
    image_items_text_background: Konva.RectConfig[],
    hide_object_names: boolean | undefined,
    text_item_background: Konva.RectConfig[],
  }
  background_image?: string
  annotated_image: boolean
  cached_image?: string
}

export interface SiteCapturingLocation {
  zoom: number,
  coordinates: google.maps.LatLng | google.maps.LatLngLiteral
  rotate: number
  tilt: number
  position: number
}

export interface ImageDetailPosition {
    x: number, y: number
}

export interface SiteCapturing extends SiteCapturingLocation{
  id?: number,
  name: string,
  map_type: string,
  was_moved: boolean,
  polygons?: AnnotatedImagePolygons
  background_image?: string
  annotated_image: boolean
  cached_image?: string
}

export interface SitePublicChargerData {
  charge_points: ChargerPoint[]
  title: string
  quantity: number
}

export interface SitePublicData {
  street: string
  post: string
  description: string
  assessment_name: string
  score: SiteScoreDetail[]
  aggregate_score: number
  aggregate_score_name: SiteAssessmentScore
  aggregate_score_color: ScoreColorPresentation
  score_missing_reason?: ScoreMissingReason
  status: string
  status_id: string
  organization_name: string
  has_numeric_score: boolean
  geometry: Feature<Point>
  chargers: SitePublicChargerData[]
}

export interface SitePublicCaptures {
  site: SitePublicData
  images: SiteCapturingPublicImage[]
}

export interface SiteCapturingPublicImage {
  name: string,
  image?: string
}

export interface SiteCaptureUpdate extends SiteCapturingLocation {
  name: string,
  map_type: string 
}

export type SupportedMaps = 'terrain' | 'hybrid' | 'satellite' | 'street' | 'roadmap';

export const mapViewOptions: Option<SupportedMaps>[] = [
  {
    label: 'Hybrid view',
    value: 'hybrid',
  },
  {
    label: 'Roadmap view',
    value: 'roadmap',
  },
  // {
  //   label: 'Satellite view',
  //   value: 'satellite',
  // },
  // {
  //   label: 'Terrain view',
  //   value: 'terrain',
  // },
  {
    label: 'Street view',
    value: 'street',
  },
]

export const toSiteCapture = (c: SiteCapturingResponse): SiteCapturing => {
  return {
    id: c.id,
    name: c.name, 
    map_type: c.map_type, 
    zoom: c.zoom,
    rotate: c.rotate,
    tilt: c.tilt,
    position: c.position,
    was_moved: c.was_moved,
    coordinates: new google.maps.LatLng(c.coordinates[1], c.coordinates[0]),
    polygons: c.polygons,
    background_image: c.background_image,
    annotated_image: c.annotated_image,
    cached_image: c.cached_image,
  }
}


export enum DrawingState {
  NONE= 'NONE',
  READY = 'READY',
  DRAWING = 'DRAWING',
  WRITING = 'WRITING'
}

export type AnnotatedImageType = 'parking' | 'electricity' | 'charger' ;
export type DrawingType = 'rectangle' | 'polyline' | 'text' | 'image' | 'electricity' ;


export interface SelectedElement {
  type: DrawingType
  index: number
}


export interface AnnotatedImageData extends AnnotatedImagePolygons{
  siteCaptureId: number,
  image_with_polygons: Blob,
  name: string
}


export interface AnnotatedImagePolygons {
  rectangles: Konva.RectConfig[],
  polylines: Konva.LineConfig[],
  text_items: Konva.TextConfig[],
  image_items: Konva.ImageConfig[],
  image_items_text: Konva.TextConfig[],
  image_items_text_background: Konva.RectConfig[],
  text_item_background: Konva.RectConfig[],
  hide_object_names: boolean | undefined,
}
