import pluralize from 'pluralize-esm'
// If you need to create a regex expression below are some website for that
// https://www.autoregex.xyz/ -> convert text to regex with ChatGPT
// https://regexr.com/ -> interactive tool for testing regex

// Regex patterns
const removeParentheses = /\([^)]*\)/g
const isHexColor =/^#([0-9a-f]{3}){1,2}$/i
const thousandSeparator = /\B(?=(\d{3})+(?!\d))/g
export const is6DigitHexColor = /(#[0-9a-fA-F]{6})/g
export const checkIfFirstCharacterIsALetter = /^[a-zA-Z].*/
export const checkIfLatLng = /^-?([0-9]*\.\d{1,15})(\s.)?,?(\s.)?-?([0-9]*\.\d{1,15})$/
export const isFloat = /^\d+(\.\d+)?$/

// re-use canvas object for better performance
const canvasElement = document.createElement('canvas')

export class TextFormatUtils {
  public static escapeRegExp(str: string) {
    return str.replace(/[.*+?^${}()|[\]\\]/g, '\\$&') // $& means the whole matched string
  }

  public static socketText(plugLabel: string) {
    return plugLabel.replace(removeParentheses, '').trim()
  }

  public static isHexColor(text: string) {
    return isHexColor.test(text)
  }

  public static isNotNumberForPowerCables(value: string): boolean {
    const isMultipleOutput = /\W|[a-zA-Z]/
    return isMultipleOutput.test(value)
  }

  public static capitalize(text: string) {
    // if you want to see the performance improvement https://stackoverflow.com/a/1026069/
    return text[0].toUpperCase() + text.slice(1)

  }

  public static capitalizeFirstLetter(text: string) {
    // if you have no idea what this does: https://stackoverflow.com/a/60610887
    return text.replace(/(^\w|\s\w)/g, m => m.toUpperCase())
  }
  
  public static getFullNameFromEmail(text: string) {
    return TextFormatUtils.capitalizeFirstLetter(text.split('@')[0].replace(/[^a-zA-Z]/g, ' '))
  }

  public static getFirstNameFromEmail(text: string) {
    const emailName = text.split('@')[0]
    return TextFormatUtils.capitalizeFirstLetter(emailName.split('.')[0].replace(/[^a-zA-Z]/g, ' '))
  }

  // if value is 0 then by default plurazlie adds s. that's why we have the min 1
  public static pluralize(text: string, size: number) {
    return pluralize(text, Math.max(size, 1))
  }

  public static formatNumberWithCommas(text: string): string {
    return text.replace(thousandSeparator, ',')
  }

  public static convertToHumanReadable(key: string): string {
    // Convert the key from snake_case to Title Case
    return key
      .replace(/_/g, ' ') // Replace underscores with spaces
      .replace(/\b\w/g, (char) => char.toUpperCase()) // Capitalize the first letter of each word
  }
}


export class TextMatchUtils {
  public static isHexColor(text: string) {
    return isHexColor.test(text)
  } 
}


export class TextHelperUtils {
  public static getTextWidth(text: string, font: string) {
    const canvas = canvasElement
    const context = canvas.getContext('2d')

    if (!context) {
      return 0
    }

    context.font = font
    const metrics = context.measureText(text)
    return metrics.width
  }
  
  public static getCssStyle(element: HTMLElement, prop: string) {
    return window.getComputedStyle(element, null).getPropertyValue(prop)
  }
  
  public static getCanvasFont(el = document.body) {
    const fontWeight = this.getCssStyle(el, 'font-weight') || 'normal'
    const fontSize = this.getCssStyle(el, 'font-size') || '16px'
    const fontFamily = this.getCssStyle(el, 'font-family') || 'Times New Roman'
    
    return `${fontWeight} ${fontSize} ${fontFamily}`
  } 
}
