// if we are going to change this
//https://github.com/cagataycivici/primevue-tailwindcss-presets/blob/main/presets/lara/drawer/index.js

export default {
  root: ({ props }) => ({
    class: [
      // Flexbox
      'tw-flex flex-col',

      // Position
      'tw-relative',
      { '!tw-transition-none !tw-transform-none !tw-w-screen !tw-h-screen !tw-max-h-full !tw-top-0 !tw-left-0': props.position === 'full' },

      // Size
      {
        'tw-h-full tw-w-96': props.position === 'left' || props.position === 'right',
        'tw-h-auto tw-w-full': props.position === 'top' || props.position === 'bottom',
      },

      // Shape
      'tw-border-0 dark:border',
      'tw-shadow-lg',

      // Colors
      'tw-bg-surface-0 tw-dark:bg-surface-800',
      'tw-text-surface-700 tw-dark:text-white/80',
      'tw-dark:border-surface-700',

      // Transitions
      'tw-transition-transform',
      'tw-duration-500',

      // Misc
      'tw-pointer-events-auto',
    ],
  }),
  header: {
    class: [
      'tw-display-none',
    ],
  },
  title: {
    class: ['tw-font-bold text-lg'],
  },
  icons: {
    class: ['tw-flex items-center'],
  },
  closeButton: {
    class: [
      'tw-relative',

      // Flexbox and Alignment
      'tw-flex items-center tw-justify-center',

      // Size and Spacing
      'tw-mr-2',
      'tw-last:mr-0',
      'tw-w-8 tw-h-8',

      // Shape
      'tw-border-0',
      'tw-rounded-full',

      // Colors
      'tw-text-surface-500',
      'tw-bg-transparent',

      // Transitions
      'tw-tw-transition duration-200 ease-in-out',

      // States
      'tw-tw-hover:text-surface-700 tw-dark:hover:text-white/80',
      'tw-tw-hover:bg-surface-100 tw-dark:hover:bg-surface-800/80',
      'tw-tw-focus:outline-none tw-focus:outline-offset-0 focus:ring tw-focus:ring-inset',
      'tw-tw-focus:ring-primary-400/50 tw-dark:focus:ring-primary-300/50',

      // Misc
      'overflow-hidden',
    ],
  },
  closeButtonIcon: {
    class: [
      // Display
      'tw-inline-block',

      // Size
      'tw-w-4',
      'tw-h-4',
    ],
  },
  content: {
    class: [
      'tw-p-0',
      'tw-h-full',
      'tw-w-full',
      'tw-grow',
      'tw-pointer-events-auto',
    ],
  },
  mask: ({ props }: any) => ({
    class: [
      // Transitions
      'tw-pointer-events-none',
      'tw-transition-all',
      'tw-duration-500',
    ],
  }),
  transition: ({ props }: any) => {
    return props.position === 'top'
      ? {
        enterFromClass: 'tw-translate-x-0 -tw-translate-y-full tw-translate-z-0 tw-mask-active',
        leaveToClass: 'tw-translate-x-0 -tw-translate-y-full tw-translate-z-0 tw-mask-active',
      }
      : props.position === 'bottom' ? {
        enterFromClass: 'tw-translate-x-0 tw-translate-y-full tw-translate-z-0 tw-mask-active',
        leaveToClass: 'tw-translate-x-0 tw-translate-y-full tw-translate-z-0 tw-mask-active',
      }
        : props.position === 'left' ? {
          enterFromClass: '-tw-translate-x-full tw-translate-y-0 tw-translate-z-0 tw-mask-active',
          leaveToClass: '-tw-translate-x-full tw-translate-y-0 tw-translate-z-0 tw-mask-active',
        } : props.position === 'right' ? {
          enterFromClass: 'tw-translate-x-full tw-translate-y-0 tw-translate-z-0 tw-mask-active tw-duration-500 tw-ease-out',
          leaveToClass: 'tw-translate-x-full tw-translate-y-0 tw-translate-z-0 tw-mask-active',
        } : {
          enterFromClass: 'tw-opacity-0 tw-mask-active',
          enterActiveClass: 'tw-transition-opacity tw-duration-500 tw-ease-out',
          leaveActiveClass: 'tw-transition-opacity tw-duration-500 tw-ease-out',
          leaveToClass: 'tw-opacity-0 tw-mask-active',
        }
  },
}