import { GeometryGeojsonTypes } from '@/utils/layers/layout-vector-config'
import { ColoringModeDatasetData, DatasetColoringAlgorithm } from '@/utils/layers/layout-config'

export type CategoryType = 'POI' | 'LAYER' | 'FEASIBILITY' | 'Public'
export type GenericWorkstationType = 'ORGANIZATION'

export enum BatchStatus {
  uploadingInProgress = 'UPLOADING_IN_PROGRESS',
  fileStoredInDb = 'FILE_STORED_IN_DB',
  processingUploadedDataCompleted = 'PROCESSING_UPLOADED_DATA_COMPLETED',
  processed = 'PROCESSED'
}


export interface GenericDatasetGeoJsonProperties {
  color: string
  label: string
  name: string,
  value: string,
}

export interface PrimaryDatasetData {
  name: string
  description: string
  ui_location: CategoryType
}

export interface GenericDataset {
  dataset_id: number
  name: string
  description: string
  failed_status: BatchStatus
  ui_location: CategoryType
  rendering_base_color: string
  icon_label: string
  icon_property_shown_below: string
  min_zoom?: number
  use_in_find_locations: boolean
  accessType: GenericWorkstationType
  content_type: GeometryGeojsonTypes
  coloring_property?: string,
  coloring_algorithm?: DatasetColoringAlgorithm,
  coloring_mode?: ColoringModeDatasetData,
  tooltip_properties: TooltipProperty[]
}

export interface TooltipProperty {
  property: string;
  visible: boolean;
  coloring_type: 'NUMBER' | 'ENUM' | 'NONE'
}

export interface DatasetBatch {
  status: string
  error_message: string
  version: string
  process_start_time: string
  source_name: string
  dataset_name: string
}

export const DEFAULT_DATASET_COLOR = '#3548f4'
