import {newEventBus} from '@/utils/eventBus.utils'
import {Layer} from '@deck.gl/core'
import {GoogleMapsOverlay} from '@deck.gl/google-maps'
import {MapLayersEvent} from '@/libs/MapLayers/types'
import {validateDeckGlLeftClickEvent} from '@/utils/deck-gl.utils'
import {MVTLayer, MVTLayerProps} from '@deck.gl/geo-layers'
import DodonaBackend from '@/libs/api/api-client'
import {MapLayerIds, MapLayerTags} from '@/utils/layers/layout-config'
import {GeometryDensity, GeometryType} from '@/utils/layers/layout-vector-config'
import {Feature} from 'geojson'

export type { Filter } from '@/libs/MapLayers/types'

export interface LayerSetEventArgs {
  id: string
  tags?: string[]
  layer: google.maps.Data
}

export interface LayerContentType {
  type: GeometryType,
  density: GeometryDensity
}

interface Cleaner {
  clear(): void
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
function isCleaner(layer: any): layer is Cleaner {
  return typeof layer === 'object' && typeof layer?.clear === 'function'
}

/**
 * Layers plugin
 *
 * Provides an improved interface (in comparison to old filter & map) for
 * full control over data layers displayed on the map
 *
 * Responsibilities & goals:
 *  - seamless data layers management
 *  - control what is visible on the map
 *
 * Why not store (vuex?)
 *  - Google Maps provide its own data layer
 *  - no need for immutability
 *
 * @param layers
 */
export default class MapLayers {
  private googleMapLayers = new Map<string, google.maps.MVCObject>()
  private map: google.maps.Map | null = null
  private deckOverlay: GoogleMapsOverlay | null = null
  private deckLayers: { layer: Layer; zIndex: number; tag?: MapLayerTags }[] = []
  private tags: Record<string, string[]> = {}
  private eventBus = newEventBus<MapLayersEvent>()

  /**
   * The amount of MVT layers currently being loaded
   * @private
   */
  private mvtLayersLoading = 0
  private abortSignals: AbortSignal[] = []

  constructor() {
    this.registerFetchListener()
  }

  get events() {
    return this.eventBus
  }

  pickFeaturesAtPoint(x: number, y: number, layerIds?: string[]): Feature[] {
    if (!this.map || !this.deckOverlay) {
      return []
    }

    return (this.deckOverlay.pickMultipleObjects({
      x,
      y,
      layerIds,
    }) ?? []).map(o => o.object as Feature)
  }

  deckGlLayers(): Layer[] {
    return this.deckLayers
      .toSorted((a, b) => a.zIndex - b.zIndex)
      .map(l => l.layer.clone({}))
  }

  onMvtLayerLoaded() {
    this.mvtLayersLoading--
    if (this.mvtLayersLoading <= 0) {
      this.abortSignals = []
      this.events.$emit('mvt-load-end')
    }
  }

  setMap(map: google.maps.Map | null = null): void {
    if (this.map === map) {
      return
    }

    this.eventBus.$emit('map', map)
    this.map = map

    const deck = new GoogleMapsOverlay({
      layers: [],
      useDevicePixels: false,
      onLoad: () => {
        // Prevent the context menu opening, specifically on windows+chrome
        const deckCanvas = document.getElementById('deckgl-overlay') as HTMLCanvasElement | null
        if (!deckCanvas) {
          console.warn('Canvas not initialized')
          return
        }

        deckCanvas.addEventListener('contextmenu', e => e.preventDefault())
      },
      onHover: (info) => {
        const { x, y, layer, object, index } = info

        if (layer) {
          this.map?.setOptions({ draggableCursor: 'pointer' })
        } else {
          this.map?.setOptions({ draggableCursor: '' })
        }

        const pickedObjects = deck.pickMultipleObjects({
          x,
          y,
        }) ?? []

        if (index === -1 || !layer || !object || pickedObjects.length === 0) {
          this.eventBus.$emit('tooltip', { show: false })
          return
        }

        if (!((layer.props as any).hasTooltip)) {
          this.eventBus.$emit('tooltip', { show: false })
          return
        }

        this.eventBus.$emit('tooltip', { show: true, layer, features: pickedObjects.map(po => po.object) })
        return
      },
      onClick: (info, event) => {
        const { valid } = validateDeckGlLeftClickEvent(info, event)

        if (!valid) {
          return
        }

        this.eventBus.$emit('map-click', { info, event })
      },
    })

    this.deckOverlay = deck
    this.deckOverlay.setMap(this.map)
    this.refreshLayers()
  }

  getMap(): google.maps.Map | null {
    return this.map
  }

  getRestriction(): google.maps.LatLngBounds | undefined {
    if (!this.map) {
      return
    }

    const mapRestriction = this.map.get('restriction') as
      | google.maps.MapRestriction
      | undefined
    if (!mapRestriction) {
      return
    }

    return mapRestriction.latLngBounds as google.maps.LatLngBounds
  }

  /**
   *  Z index should follow the convention:
   *
   * [40000 - 41000] most necessary polygons(workspace limit), icons (sites), events(move site)
   * [36000 - 37000] sparse icons
   * [34000 - 35000] dense icons
   * [30000 - 31000] sparse lines
   * [28000 - 29000] dense lines
   * [24000 - 25000] sparse polygons
   * [21000 - 22000] dense polygons
   *
   */
  addLayer(layer: Layer, { tag, content }: { tag?: MapLayerTags, content?: LayerContentType } = { tag: undefined, content: undefined }): void {
    this.removeLayerById(layer.id)

    const zIndex = this.getLayerZIndex(layer, content)

    this.deckLayers.push({ layer, zIndex, tag })
    this.events.$emit('deck-layer-set', { id: layer.id, layer, tag })
    this.refreshLayers()
  }

  removeAllLayers(): void {
    this.deckLayers = []
    this.refreshLayers()
  }

  removeLayerById(id: string): void {
    const originalLength = this.deckLayers.length
    this.deckLayers = this.deckLayers.filter((entry) => entry.layer.id !== id)

    if (this.deckLayers.length !== originalLength) {
      this.refreshLayers()
    }
  }

  removeLayerByTag(tag: MapLayerTags): void {
    const originalLength = this.deckLayers.length

    this.deckLayers = this.deckLayers.filter((entry) => entry.tag !== tag)

    if (this.deckLayers.length !== originalLength) {
      this.refreshLayers()
    }
  }

  getLayerById(id: string): Layer | undefined {
    return this.deckLayers.find((entry) => entry.layer.id === id)?.layer
  }

  private refreshLayers(): void {
    if (!this.deckOverlay) {
      return
    }

    this.deckOverlay.setProps({
      layers: this.deckGlLayers(),
    })
  }

  /**
   * @deprecated
   */
  private tag(id: string, tags: string[] = []): void {
    tags.forEach((tag) => {
      if (this.tags[tag]) {
        this.tags[tag].push(id)
      } else {
        this.tags[tag] = [id]
      }
    })
  }

  private forEachTagged(
    tag: string,
    callback: (layer: google.maps.MVCObject, id: string) => void,
  ): void {
    (this.tags[tag] || []).forEach((id) => {
      const layer = this.googleMapLayers.get(id)

      if (layer) {
        callback(layer, id)
      }
    })
  }

  /**
   * @deprecated use getLayerById
   */
  get(id: string): google.maps.MVCObject | undefined {
    return this.googleMapLayers.get(id)
  }

  addGoogleMapsLayer(id: string, layer: google.maps.MVCObject, tags?: string[]): void {
    this.delete(id)
    this.googleMapLayers.set(id, layer)
    this.tag(id, tags)

    if (layer instanceof google.maps.MVCArray) {
      layer.forEach((e) => e.set('map', this.map))
    } else {
      layer.set('map', this.map)
    }

    this.eventBus.$emit('layer-set', { id, layer, tags } as LayerSetEventArgs)
  }

  /**
   * Removes one or all map layers
   *
   * @deprecated use removeLayerById (right now this is still used for markers check MapContainer.vue)
   */
  delete(id: string): void {
    this.removeLayerById(id)

    if (this.googleMapLayers.has(id)) {
      const layer: google.maps.MVCObject | undefined = this.googleMapLayers.get(id)

      if (layer instanceof google.maps.MVCArray) {
        layer.forEach((e) => {
          e.visible = false
          e.set('map', null)
        })
      } else if (layer instanceof google.maps.MVCObject) {
        layer.set('map', null)
      }

      if (isCleaner(layer)) {
        layer.clear()
      }

      this.googleMapLayers.delete(id)
      this.events.$emit('layer-removed', { id })
    }

    this.gc(id)
  }


  /**
   * Deletes all layers using these tag
   */
  deleteGoogleMapsLayerByTag(tag: MapLayerTags): void {
    this.forEachTagged(tag, (l: google.maps.MVCObject, id: string) => {
      this.delete(id)
    })
    this.events.$emit('layer-removed', { tag })
  }

  private getLayerZIndex(layer: Layer, content?: LayerContentType): number {
    const id = layer.id as MapLayerIds

    switch (true) {
      case id === MapLayerIds.moveSite:
        return 40600
      case id === MapLayerIds.selectedSite:
        return 40503
      case id === MapLayerIds.hoveredSite:
        return 40502
      case id === MapLayerIds.hoveredSiteHighlight:
        return 40501
      case id === MapLayerIds.workspaceSites:
        return 40500
      case id === MapLayerIds.wards:
        return 40400
      case id === MapLayerIds.landAnalysisSearch:
        return 40300
      case id === MapLayerIds.workspaceBorder:
        return 40200
      case id.includes(MapLayerIds.utilization):
        return 40100
      case id === MapLayerIds.infillLsoa:
        return 40050
      case id === MapLayerIds.ownChargers:
        return 40040
    }

    if (!content) {
      return 0
    }

    const { type, density } = content

    switch (type) {
      case GeometryType.point:
        return density === GeometryDensity.sparse ? 36500 : 35500
      case GeometryType.line:
        return density === GeometryDensity.sparse ? 30500 : 28500
      case GeometryType.polygon:
        return density === GeometryDensity.sparse ? 24500 : 21500
    }

    return 0
  }

  /**
   * @deprecated
   */
  private gc(id?: string): void {
    for (const t in this.tags) {
      if (id) {
        // id removed, find if it was tagged anywhere and remove it..
        this.tags[t] = this.tags[t].filter((taggedId) => taggedId !== id)
      }

      if (this.tags[t].length === 0) {
        delete this.tags[t]
      }
    }
  }

  /**
   * Inspect fetch requests for vector tile requests so we can show a loading indicator
   */
  private registerFetchListener() {
    const originalFetch = window.fetch

    window.fetch = async (...args) => {
      let url = args[0]
      const init = args[1]

      try {
        if (url instanceof URL) {
          url = url.toString()
        } else if (url instanceof Request) {
          url = url.url
        }

        if (url.includes(DodonaBackend.VECTOR_DATASET_URL) || url.includes(DodonaBackend.VECTOR_FIND_LOCATION_URL)) {
          if (this.mvtLayersLoading <= 0) {
            // Hook into the start of tile load
            this.onTileLoadStart()
          }

          // Save the signals to remove the loading indicator on abort
          if (init?.signal) {
            const signal: AbortSignal = init.signal
            signal.onabort = () => {
              this.onMvtLayerLoaded()
              // As long as one signal aborts, we can discard all the others
              this.abortSignals.forEach(as => as.onabort = null)
              this.abortSignals = []
            }

            this.abortSignals.push(signal)
          }
        }
      } catch (e) {
        console.error(e)
      }

      return originalFetch(...args)
    }
  }

  private onTileLoadStart() {
    if (!this.deckOverlay) {
      return
    }

    this.mvtLayersLoading = 0

    const layers = this.deckGlLayers()
    layers.forEach(layer => {
      const props = layer.props as MVTLayerProps

      const isMvt = layer instanceof MVTLayer
      const isVisible = (props.maxZoom ?? 0) > (this.map?.getZoom() ?? -1)
        || (props.minZoom ?? 21) < (this.map?.getZoom() ?? 22)
      const { isLoaded } = layer

      this.mvtLayersLoading += isMvt && isVisible && !isLoaded ? 1 : 0
    })

    this.events.$emit('mvt-load-start')
  }

  /**
   * Safeguard for developers.
   * Checks if the MVT Layer props are set up correctly so it works with the dataset.
   */
  private validateMvtLayerConfiguration(layer: unknown): void {
    if (!(layer instanceof MVTLayer)) {
      return
    }

    let message = `Layer ${layer.id} was not configured correctly.`

    if (typeof layer.props.data === 'string' && !layer.props.data.includes('?t=')) {
      message += ' A timestamp query param is missing from the data URL.'
        + ' This will cause the layer to not rerender properly when filter properties change.'
        + ' Append ?t=${Date.now()} to the data URL to fix this.'

      throw new Error(message)
    }

    if (layer.props.loadOptions.fetch && layer.props.loadOptions.fetch.method !== 'POST') {
      message += ' The layer must use a POST request.'

      throw new Error(message)
    }
  }
}
