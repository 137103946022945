import {Option} from '@/components/DSelect.vue'
import {SiteUtils} from '@/models/SiteSelection/Site.model'
import {OriginalLabel, SiteStatus} from '@/types/workspace'
import {keyBy} from 'lodash'
import {defineStore} from 'pinia'

export type SiteStatusStore = ReturnType<typeof useSiteStatusStore>

export const useSiteStatusStore = defineStore('siteStatusStore', {
  state: () => ({
    allSiteStatuses: [] as SiteStatus[],
  }),
  getters: {
    // the statuses are order by configuration layout and only the active ones are shown
    siteStatuses(): SiteStatus[] {
      const purpose = this.getFullActiveStatuses.filter(s => s.is_default)
      const others = this.getFullActiveStatuses.filter(s => !s.is_default && s.protected).toSorted(SiteUtils.sortByUUID)
      const dynamic = this.getFullActiveStatuses.filter(s => s.position !== 0).toSorted((a, b) => a.position - b.position) 

      return [
        ...purpose,
        ...dynamic,
        ...others,
      ]
    },
    getFullStatuses(): SiteStatus[] {
      return this.allSiteStatuses
    },
    getFullActiveStatuses(): SiteStatus[] {
      return this.allSiteStatuses.filter((s) => !s.archived)
    },
    getFullArchivedStatuses(): SiteStatus[] {
      return [
        ...new Set<SiteStatus>(this.allSiteStatuses.filter((s) => s.archived)),
      ]
    },
    getApprovedStatus(): SiteStatus | null {
      return this.allSiteStatuses
        .find((s) => s.original_label === 'APPROVED' as OriginalLabel) || null
    },
    getActiveStatuses(): string[] {
      return this.siteStatuses
        .map(({ status }) => status)
    },
    getActiveStatusOptions(): Option[] {
      return this.getActiveStatuses.map((status: string) => {
        return {
          value: status,
          label: status,
        }
      })
    },
    getArchivedStatusOptions(): (id: string) => Option[] {
      return (statusId: string) => {
        return this.getArchivedStatuses(statusId)
          .map((status: string) => {
            return {
              value: status,
              label: status,
              disabled: true,
            }
          })
      }
    },
    getArchivedStatuses() {
      return (siteId: string) => {
        return [...new Set<string>(this.allSiteStatuses
          .filter((s) => s.archived && s.id === siteId)
          .map(({status}) => status)),
        ]
      }
    },
    getFullStatusesDictionary(): Record<string, SiteStatus> {
      return keyBy(this.getFullStatuses || [], 'id')
    },
    getActiveStatusDictionary(): { [key: string]: SiteStatus } {
      return keyBy(this.getFullActiveStatuses || [], 'id')
    },
    getArchivedStatusDictionary(): { [key: string]: SiteStatus } {
      return keyBy(this.getFullArchivedStatuses || [], 'id')
    },
    getStatuses(): string[] {
      return [...new Set<string>(this.allSiteStatuses.map(({ status }) => status))]
    },
  },
  actions: {
    setSiteStatuses(statuses: SiteStatus[]) {
      this.allSiteStatuses = statuses
    },

    getSiteStatusByValue(status: string): SiteStatus | undefined {
      return this.siteStatuses.find(s => s.status === status)
    },
    
    async fetchSiteStatuses() {
      this.allSiteStatuses = await this.api.getStatuses()
    },
    findById(id: string): SiteStatus | undefined {
      return this.getFullStatusesDictionary[id]
    },
  },
})