import {
  Fleet,
  PlanningLevel,
  SiteSource,
  SiteStatus,
  Workspace,
  WorkspaceSite,
} from '@/types/workspace'
import {Polygon} from 'geojson'
import {defineStore} from 'pinia'
import {MarkerStatusModelMapper} from '@/models/admin/Configuration.model'
import {ErrorToast, handleBackendError} from '@/utils/global.utils'
import {SiteAssessment} from '@/libs/auth/types'
import {useOrganizationStore} from '@/stores/organization.store'
import {Option} from '@/components/DSelect.vue'
import {editSite, editWorkspace, getWorkspace} from '@/libs/workspace'
import { useSidebarStore } from './sidebar.store'
import { useSiteStatusStore } from './site-status.store'
import { useRoute } from 'vue-router'
import { useCustomFieldsStore } from './custom-fields.store'


export type WorkspaceStore = ReturnType<typeof useWorkspaceStore>

export const useWorkspaceStore = defineStore('workspace', {
  state: () => ({
    workspace: undefined as Workspace | undefined,
    siteStatuses: [] as SiteStatus[],
    siteSources: [] as SiteSource[],
    fleets: [] as Fleet[],
    vehicleCatchmentArea: undefined as Polygon | undefined,
    isPortfolio: false,
  }),

  getters: {
    customFields() {
      return useCustomFieldsStore().customFields
    },
    planningLevel(): PlanningLevel {
      if (!this.workspace) {
        throw new Error('Attempted to get planning level without workspace')
      }

      return this.workspace.planning_level
    },

    workspaceId(): string | undefined {
      return this.workspace?.id
    },

    isLocalSiteSelectionWorkspace(): boolean {
      if (!this.workspace) {
        return false
      }

      return this.workspace.planning_level === PlanningLevel.local
    },

    workspaceSiteAssessments(): SiteAssessment[] {
      const os = useOrganizationStore()

      // @TODO refactor this to be separate store
      // plus do the same for statuses and assessment
      if (this.isPortfolio) {
        return os.activeAssessments
      }
      
      const models = this.workspace?.available_assessments
      const planningLevelId = (this.workspace as Workspace)?.planning_level
      if (!models) {
        return []
      }

      return os.activeAssessments.filter((model: SiteAssessment) => {
        return models.includes(model.assessment_name) && model.planning_level === planningLevelId
      })
    },

    hasAssessment(): (name: string) => boolean {
      return (assessmentName: string) => {
        if (!assessmentName.includes('_')) {
          console.warn('Check if you passed assessment_name instead of name, the name should contain an underscore character')
        }

        return this.workspaceSiteAssessments
          .some(({ assessment_name }) => assessment_name === assessmentName)
      }
    },

    hasWorkspaceSiteAssessments(): boolean {
      return this.workspaceSiteAssessments.length > 0
    },

    workspaceSmartPlanningSiteAssessments(): SiteAssessment[] {
      return this.workspaceSiteAssessments.filter((assessment: SiteAssessment) => {
        return assessment.show_on_workspace_smart_planning_tab
      })
    },

    workspaceHasSmartPlanningSiteAssessments(): boolean {
      return this.workspaceSmartPlanningSiteAssessments.length > 0
    },

    workspaceSiteAssessmentsOption(): Option<string>[] {
      return this.workspaceSiteAssessments.map((assessment: SiteAssessment) => {
        return {
          label: assessment.name,
          value: assessment.assessment_name,
        }
      })
    },
  },

  actions: {
    async fetchWorkspace(): Promise<void> {
      const sss = useSiteStatusStore()
      const route = useRoute()
      const workspaceId = route.params.workspaceId as string

      if (!workspaceId) {
        throw new Error('Fetch workspace id missing')
      }

      return await Promise.all([
        getWorkspace(this.api, workspaceId),
        sss.fetchSiteStatuses(),
      ]).then(([ws]) => {
        console.debug('workspace loaded', { ...ws })

        // update the workspace statuses to the newest version in case the user adds new statuses or renames them
        this.siteStatuses = sss.siteStatuses
        this.isPortfolio = false
        // 
        ws.sites = MarkerStatusModelMapper.toSiteStatuses(ws.sites, sss.getFullActiveStatuses)
        this.setWorkspace(ws)
      })
    },
    async editWorkspace(params: Partial<Workspace>): Promise<void> {
      if (!this.workspace) {
        throw new ErrorToast('Edit Workspace', 'Workspace not found')
      }

      const ws = await editWorkspace(this.api, this.workspace.id, params)
      this.workspace = { ...this.workspace, ...ws }
    },

    async deleteSite(id: string) {
      if (!this.workspace) {
        console.warn('Workspace site can not be deleted because workspace is not loaded')
        return
      }

      await this.api.deleteSite(id)
      const workspace = { ...this.workspace }
      workspace.sites = this.workspace.sites.filter((s) => s.id !== id) || []
      this.workspace = workspace
    },

    async createSite(
      workspaceId: string,
      coordinates: google.maps.LatLngLiteral,
      assessmentName: string | undefined,
    ): Promise<WorkspaceSite> {
      const site = await this.api.createSite(
        this.api,
        workspaceId,
        coordinates,
        assessmentName,
      )

      this.addSite(site)
      return site
    },

    addSite(site: WorkspaceSite): WorkspaceSite | undefined {
      const siteStatuses = useSiteStatusStore()
      if (!this.workspace) {
        return
      }

      const sites = [...this.workspace.sites]

      const newSite = MarkerStatusModelMapper.toSiteStatuses([site], siteStatuses.getFullStatuses)

      sites.push(newSite[0])

      this.workspace = {
        ...this.workspace,
        sites: sites,
      }

      return newSite[0]
    },

    /**
     * Update a site and also update it on the workspace object.
     */
    async updateSite(siteId: string, payload: Record<string, string | number>): Promise<WorkspaceSite> {
      const siteStatuses = useSiteStatusStore()
 
      if (!this.workspace) {
        console.error('Can\'t update site, workspace is undefined')
        throw new ErrorToast('Site', 'Error updating site', 'danger')
      }

      const site = await editSite(this.api, siteId, payload)
      const sites = [...this.workspace.sites]

      // change old site with new one
      const index = sites.findIndex((s) => s.id === site.id)

      // Set correct status type
      const newSite = MarkerStatusModelMapper.toSiteStatuses([site], siteStatuses.getFullActiveStatuses)
      sites[index] = newSite[0]

      this.workspace = {
        ...this.workspace,
        sites: sites,
      }

      return site
    },

    updateSiteChecklistCount(siteId: string, count: number): void {
      if (!this.workspace) {
        console.error('Can\'t modify site checklist, workspace is undefined')
        throw new ErrorToast('Site', 'Error updating site', 'danger')
      }

      const site = this.workspace.sites.find((s) => s.id === siteId)

      if (!site) {
        console.error(`Can't update site, site with ${siteId} not found`)
        throw new ErrorToast('Site', 'Error updating site', 'danger')
      }

      site.properties.checklist_item_count = count
    },

    addSites(sites: WorkspaceSite[]): void {
      if (!this.workspace) {
        throw new Error('Workspace missing')
      }

      this.workspace = {
        ...this.workspace,
        sites: [...this.workspace.sites, ...sites],
      }
    },

    async moveSite(site: WorkspaceSite, latLng: google.maps.LatLngLiteral): Promise<WorkspaceSite> {
      const siteStatuses = useSiteStatusStore()

      if (!this.workspace) {
        throw new Error('Workspace missing') 
      }

      let newSite = await this.api.moveSite(site.id, latLng)

      const sites = this.workspace.sites.filter((s) => s.id !== site.id)
      newSite = MarkerStatusModelMapper.toSiteStatuses([newSite], siteStatuses.getFullActiveStatuses)[0]
      sites.push(newSite)

      this.workspace = {
        ...this.workspace,
        sites: sites,
      }

      return newSite
    },

    setWorkspace(w: Workspace) {
      this.workspace = w
    },
    
    clearWorkspace() {
      this.workspace = undefined
    },

    async getLatestWorkspaceNote(): Promise<string> {
      if (!this.workspaceId) {
        throw new Error('Workspace is undefined')
      }

      return this.api.getLatestWorkspaceNote(this.workspaceId).then((workspaceNote) => {
        if (!workspaceNote) {
          return ''
        }

        return workspaceNote.note
      })
    },
    
    async refetchWorkspaceSites() {
      const siteStatuses = useSiteStatusStore()

      if (!this.workspace) {
        throw new Error('Workspace is undefined')
      }
      const sbs = useSidebarStore()
      sbs.close()
      try {
        const workspace = await this.api.getWorkspace(this.workspace.id)
        workspace.sites = MarkerStatusModelMapper.toSiteStatuses(workspace.sites, siteStatuses.getFullActiveStatuses)
        this.setWorkspace(workspace)
        this.workspace.number_of_sites = this.workspace.sites.length
      } catch (e) {
        handleBackendError('Failed to fetch workspace', e)

        this.workspace.sites = []
      }
    },

    async updateWorkspaceStatus(workspaceStatusId: string): Promise<void> {
      if (!this.workspace) {
        throw new Error('Workspace is undefined')
      }

      const workspace = await editWorkspace(this.api, this.workspace.id, { status_id: workspaceStatusId })
      this.workspace.status_id = workspace.status_id
    },

    getSiteById(id: string): WorkspaceSite | undefined {
      return this.workspace?.sites.find(s => s.id === id) 
    },
    locallyUpdateSite(site: WorkspaceSite) {
      if (!this.workspace) {
        console.warn('workspace is note defiend')
        return
      }

      const siteIndex = this.workspace.sites.findIndex(s => s.id === site.id)
      this.workspace.sites[siteIndex] = site
    },
  },
})
