
import { AppRegionData, CountryCode, countryI18n, RegionCode, SupportedRegion } from '@/types/app'
import {AxiosError, AxiosResponse} from 'axios'
import {ErrorUtils} from '@/utils/error.utils'


export default class GlobalUtils {
  // This property cannot be moved to a Pinia store as it causes a cascade of issues
  private static appRegion: SupportedRegion = RegionCode.UK
  private static appCountry: CountryCode = CountryCode.UK
  private static appCountryLoaded = false
  public static appInfo(): any {
    const text = `%cDodona eMobility frontend client; version: ${MF_APP_VERSION}, build time: ${BUILD_TIME}`
    const style =
      'background-color: #00B2FF; color: #E1E5F1; margin: 10px; padding: 10px; border: 2px solid #E1E5F1; font: Courier'

    /* eslint-disable no-console */
    console.log(text, style)
  }

  public static getAppRegion(): SupportedRegion {
    return this.appRegion
  }

  public static setAppRegion(region: SupportedRegion): void {
    this.appRegion = region
  }

  public static getAppCountry(): CountryCode {
    return this.appCountry
  }

  public static setAppCountry(region: CountryCode): void {
    this.appCountry = region
    this.appCountryLoaded = true
  }

  public static i18nRegion(): string {
    return countryI18n.get(this.appCountry) as string || 'en-gb'
  }

  public static env(): string {
    return import.meta.env.MODE
  }

  public static isProduction(): boolean {
    return import.meta.env.MODE.toLowerCase().includes('production')
  }
  
  public static isDev(): boolean {
    return import.meta.env.MODE.toLowerCase().includes('development')
  }

  public static isTesting(): boolean {
    return import.meta.env.MODE.toLowerCase().includes('testing')
  }

  public static isStaging(): boolean {
    return import.meta.env.MODE.toLowerCase().includes('staging')
  }

  public static isPreproduction(): boolean {
    return import.meta.env.MODE.toLowerCase().includes('preprod')
  }

  public static supportedRegionData(): AppRegionData[] {
    let prefix = 'testing'

    if (GlobalUtils.isProduction()) {
      prefix = 'emobility'
    } else if (GlobalUtils.isStaging()) {
      prefix = 'staging'
    } else if (GlobalUtils.isPreproduction()) {
      prefix = 'preprod'
    }

    return [
      {
        countryCode: RegionCode.UK,
        url: `https://${prefix}.dodonaanalytics.com`,
      },
      {
        countryCode: RegionCode.US,
        url: `https://${prefix}-us.dodonaanalytics.com`,
      },
    ]
  }

  public static async delayedPrint(ms: number) {
    await new Promise((r) => setTimeout(r, ms))
    window.print()
  }

  public static getBaseUrl(): string {
    return import.meta.env.APP_DODONA_API_BASE_URL as string
  }

  public static getLocalImage(path: string): string {
    let pathFilename = path
    if (!this.isDev()) {
      const sections = path.split('/')
      pathFilename = '/assets/' + sections[sections.length - 1]
    }
    return new URL(pathFilename, import.meta.url).href
  }

  public static sentryEnabled(): boolean {
    return GlobalUtils.isPreproduction() || GlobalUtils.isProduction() || GlobalUtils.isStaging()
  }
}

type ErrorType = 'warning' | 'danger'; 

export interface BackedEndpointResponse {
  detail: string
}

export function handleAxiosResponseWarning(title: string, error: AxiosError<BackedEndpointResponse>) {
  const response = error.response as AxiosResponse<BackedEndpointResponse>
  if (error instanceof AxiosError) {
    if (response?.status === 400) {
      throw new ErrorToast(title, response?.data.detail, 'warning')
    }
  }
}

export function handleBackendError(title: string, error: AxiosError<BackedEndpointResponse>, defaultError: string = '') {
  const response = error.response as AxiosResponse<BackedEndpointResponse>
  if (error instanceof AxiosError) {
    if (response?.status >= 400 && response?.status < 500) {
      throw new ErrorToast(title, response?.data.detail, 'warning')
    } else if (error.response?.status as number >= 500) {
      throw new ErrorToast(title, response?.data.detail || defaultError, 'danger')
    }
  } else {
    throw new ErrorToast(title, defaultError, 'danger')
  }
}

export class ErrorToast extends Error {
  constructor(
    public title: string,
    public message: string,
    public type: ErrorType = 'danger',
    public appendToast = true,
  ) {
    super(message)

    // needed for CustomError instanceof Error => true
    Object.setPrototypeOf(this, new.target.prototype)
    this.name = this.constructor.name

    // Maintains proper stack trace for where our error was thrown (only available on V8)
    if (Error.captureStackTrace) {
      Error.captureStackTrace(this, this.constructor)
    }
  }
}

export enum LocalStorageKey {
  DISABLE_DELETE_MODAL = 'disableDeleteModal',
  DISABLE_DELETE_PORTFOLIO_MODAL = 'disableDeletePortfolioModal',
  DISABLE_BULK_DELETE_MODAL = 'disableBulkDeleteModal',
  MAP_KEY_OPENED = 'map-key-opened',
  PARKING_TOOLTIP_HAS_SEEN = 'parkingTooltipHasSeen',
  GOOGLE_MAP_TYPE = 'googleMapType',
  PANEL_CONTAINER_LEFT = 'panel-container-left',
  PANEL_CONTAINER_RIGHT = 'panel-container-right',
  PANEL_CONTAINER_BOTTOM = 'panel-container-bottom',
  SIDE_NAV_COLLAPSED = 'navbar-collapsed',
  FIND_LOCATIONS_VALUES = 'find-locations-values',
  FIND_LOCATIONS_GENERIC_VALUES = 'find-locations-generic-values',
  FIND_LOCATIONS_SELECTED_OPTIONS = 'find-locations-selected-options',
  FIND_LOCATIONS_SELECTED_GENERIC_OPTIONS = 'find-locations-selected-generic-options',
  ADD_SITE_ASSESSMENT = 'add-site-assessment',
  SMART_PLANNING_ASSESSMENT = 'smart-planning-assessment',
  UPLOAD_SITES_ASSESSMENT = 'upload-sites-assessment',
  PANEL_TOOLBOX_TAB = 'panel-toolbox-tab',
  CATCHMENT_AREA_CONFIG = 'catchment-area-config',
  DISABLE_DELETE_MODAL_ADMIN_CHECKBOX = 'disable-delete-modal-admin-checkbox',
  SITE_DENSITY = 'site-density',
  PANEL_LEFT_TAB = 'panel-left-tab',
  PANEL_RIGHT_TAB = 'panel-right-tab'
}

export class LocalStorage {
  private static readonly storage = localStorage

  public static delete(key: LocalStorageKey) {
    this.storage.removeItem(key.toString()) 
  }

  public static getItem(key: LocalStorageKey, defaultValue: string = ''): string {
    return this.storage.getItem(key.toString()) || defaultValue
  }

  public static setItem(key: LocalStorageKey, defaultValue: string | number | boolean | Object) {
    return this.storage.setItem(key.toString(), '' + defaultValue)
  }

  public static isTrue(key: LocalStorageKey): boolean {
    return this.getItem(key, 'false') === 'true'
  }

  public static json(key: LocalStorageKey, defaultValue: Object): Object {
    try {
      const payload = this.storage.getItem(key.toString())
      if (payload !== null) {
        return JSON.parse(payload)
      }
    } catch (err) {
      ErrorUtils.captureError(err)
    }
    return defaultValue
  }
}
