import DodonaBackend from '@/libs/api/api-client'

// https://stackoverflow.com/questions/64118679/question-about-vue-3-typescript-and-augmenting-types-for-use-with-plugins

export default function (backendService: DodonaBackend) {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  return function (app: any): void {
    app.config.globalProperties.$API = backendService
  }
}

declare module '@vue/runtime-core' {
  interface ComponentCustomProperties {
    $API: DodonaBackend
  }
}
