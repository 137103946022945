import {UserRole} from '@/auth/roles'
import {Feature, GeoJsonProperties, Geometry} from 'geojson'
import {ColorRGBA, GeometryDensity, GeometryType, GeometryZoomLevels} from '@/utils/layers/layout-vector-config'
import {PickingInfo} from 'deck.gl'
import type {MVTLayerProps} from '@deck.gl/geo-layers'
import {customUploadMarkerIcon} from '@/utils/sharedComponents.utils'
import {DEFAULT_DATASET_COLOR} from '@/models/admin/GenericDataset.model'
import {EvForecastMetric} from './layers-uk'

export enum MapLayerTags {
  poiTab = 'ui-poi-tab',
  layerTab = 'ui-layer-tab',
  landAnalysis = 'land-analysis-tab',
  feasibilityTab = 'feasibility-tab',
  utilisationTabTag = 'ui-utilisation-tab',
  catchmentArea = 'catchment-area',
  areaInsight = 'area-insight',
  workspaceBorder = 'workspace-border',
  wards = 'wards',
  substations = 'Substations',
  feeders = 'feeders',
  competitionChargers = 'competition-chargers',
  parking ='ui-parking',
  workspaceSites = 'workspace-sites',
  siteLabelOverlayTag = 'workspace-site-labels',
  siteMarkerLayerTag = 'workspace-site-markers',
  dataCoverage = 'data-coverage',
}

export enum MapLayerIds {
  workspaceSites = 'workspace-sites',
  hoveredSite = 'hovered-site',
  hoveredSiteHighlight = 'hovered-site-highlight',
  selectedSite = 'selected-site',
  dataCoverage = 'data-coverage',
  findLocations = 'find-locations',
  moveSite = 'move-site',
  utilization = 'utilization',
  catchmentArea = 'catchment-area',
  regions = 'regions',
  areaInsight = 'area-insight',
  infillLsoa = 'infill-lsoa',
  parking ='parking',
  poi = 'poi',
  competitionChargers = 'competition-chargers',
  ownChargers = 'own-chargers',
  eConnectChargers = 'eConnect-chargers',
  landAnalysisSearch = 'land-analysis-search',
  workspaceBorder = 'workspace-border',
  wards = 'wards',
}

export interface PropertyFilter {
  property_name: string
  filter: string[]
}

export type IconMapping = Record<string, {
  x: number
  y: number
  width: number
  height: number
}>


export enum DatasetColoringAlgorithm {
  percentile = 'PERCENTILE',
  minMax = 'MIN_MAX',
  standardDev = 'ST_DEV',
  byString = 'BY_STRING'
}

export enum ColoringModeDatasetData {
  gradientAlgorithm = 'GRADIENT_ALGORITHM',
  oneColorPolygon = 'ONE_COLOR_POLYGON',
  oneColorIcon = 'ONE_COLOR_ICON',
  colorFunction = 'COLOR_FUNCTION',
}

export class ColoringFunctions<G extends Geometry = Geometry, P = GeoJsonProperties> {
  fillColor: ((f: Feature<G, P>) => ColorRGBA) | ColorRGBA
  lineColor: ((f: Feature<G, P>) => ColorRGBA) | ColorRGBA
  lineWidthMinPixels?: number
  pointRadius?: ((f: Feature<G, P>) => number) | number
  highlightColor?: ((info: PickingInfo) => ColorRGBA) | ColorRGBA

  constructor(
    fillColor: ((f: Feature<G, P>) => ColorRGBA) | ColorRGBA,
    lineColor: ((f: Feature<G, P>) => ColorRGBA) | ColorRGBA,
    pointRadius?: ((f: Feature<G, P>) => number) | number,
    lineWidthMinPixels?: number,
    highlightColor?: ((info: PickingInfo<Feature<G, P>>) => ColorRGBA) | ColorRGBA,
  ) {
    this.fillColor = fillColor
    this.lineColor = lineColor
    this.lineWidthMinPixels = lineWidthMinPixels
    this.pointRadius = pointRadius
    this.highlightColor = highlightColor
  }
}


export interface MapKeyDetail {
  description?: string
  params: MapKeyParameters[]
}

export interface MapKeyParameters {
  color: [string] | [string, string, string]
  titleLeft?: string
  titleRight: string
}


export class MVTLayerConfiguration {
  name: string
  dataset: string
  genericApi: boolean
  description?: string
  group?: string
  version?: string
  minZoom?: number
  role?: UserRole
  filterProperties?: PropertyFilter[]
  color?: string | ColoringFunctions
  label?: string
  tag?: MapLayerTags
  coloring_mode?: ColoringModeDatasetData
  content_type: {type: GeometryType, density: GeometryDensity}
  hasTooltip?: boolean
  tooltipProperties?: Tooltip[]
  customTooltipComponent?: string
  icon?: string
  coloring_property?: string
  coloring_algorithm?: DatasetColoringAlgorithm
  mapKeyOverride?: MapKeyParameters[]
  pickable: boolean
  includeLabel: boolean
  iconPropertyShownBelow?: string
  highlight: boolean
  highlightColor?: string | ((info: PickingInfo) => [number, number, number, number]) | [number, number, number, number]
  canOverlap: boolean
  mapIcon?: (prop: any) => {url: string, width: number, height: number}
  onClick?: MVTLayerProps['onClick']
  iconAtlas?: string | null
  iconMapping?: IconMapping

  public constructor(private args: {
    name: string,
    dataset: string,
    description?: string,
    group?: string,
    version?: string,
    minZoom?: GeometryZoomLevels,
    role?: UserRole
    includeLabel?: boolean
    filterProperties?: PropertyFilter[]
    color?: string | ColoringFunctions,
    label?: string,
    iconPropertyShownBelow?: string,
    tag?: MapLayerTags
    content_type: {type: GeometryType, density: GeometryDensity},
    coloring_mode?: ColoringModeDatasetData,
    // if it exists it will be picked from here instead of database
    coloring_property?: string,
    mapKeyOverride?: MapKeyParameters[]

    onClick?: MVTLayerProps['onClick']

    // icon as shown on left panel. if mapIcon is not defined and this dataset should
    // be presented as Icon then this is also the icon on map
    icon?: string,

    // this is when you need complex logic for map Icon
    mapIcon?: (prop: any) => {url: string, width: number, height: number}

    iconAtlas?: string
    iconMapping?: IconMapping

    /**
     * If true, will display all feature properties in the tooltip
     */
    hasTooltip?: boolean

    /**
     * If present, will display only properties in this list in the tooltip
     */
    tooltipProperties?: Tooltip[]

    /**
     * If present, will render the component with that name as the tooltip
     */
    customTooltipComponent?: string

    /**
     * Trigger mouse events such as click, hover, etc. Defaults to true
     */
    pickable?: boolean

    /**
     * Highlight the layer on mouseover. Defaults to true
     */
    highlight?: boolean

    /**
     * If present, will be used to override the default highlight color.
     * Can be a hex RGB/RGBA color, a function or a RGBA array.
     */
    highlightColor?: string | ((info: PickingInfo) => [number, number, number, number]) | [number, number, number, number]

    /**
     * Whether the layer can have overlapping polygons. Marking it as such will send an array of all
     * polygons during hover events. Defaults to false.
     * */
    canOverlap?: boolean
  }) {

    this.name = args.name
    this.dataset = args.dataset
    this.description = args.description
    this.group = args.group
    this.version = args.version
    this.minZoom = args.minZoom
    this.role = args.role
    this.genericApi = true
    this.filterProperties = args.filterProperties
    this.coloring_mode = args.coloring_mode
    this.color = args.color
    this.label = args.label
    this.content_type = args.content_type
    this.tag = args.tag
    this.icon = args.icon
    this.mapKeyOverride = args.mapKeyOverride
    this.hasTooltip = args.hasTooltip ?? true
    this.tooltipProperties = args.tooltipProperties
    this.customTooltipComponent = args.customTooltipComponent
    this.coloring_property = args.coloring_property
    this.pickable = args.pickable ?? true
    this.highlight = args.highlight ?? true
    this.highlightColor = args.highlightColor
    this.iconPropertyShownBelow = args.iconPropertyShownBelow
    this.includeLabel = args.includeLabel ?? false
    this.mapIcon = args.mapIcon
    this.canOverlap = args.canOverlap ?? false
    this.onClick = args.onClick
    // Must be explicitly set to null and not undefined for deck.gl to treat it as unset
    this.iconAtlas = args.iconAtlas ?? null
    this.iconMapping = args.iconMapping
  }

  isItemEnabled(currentZoom: number) {
    return !(this?.minZoom && currentZoom <= this?.minZoom)
  }

  updateFilter(filterProperties: PropertyFilter[]) {
    this.filterProperties = filterProperties
  }

  getMapIcon(): (data: any) => { url: string; width: number; height: number } {
    return (data: any) => {
      if (this.mapIcon) {
        return this.mapIcon(data)
      } else if (this.icon) {
        return { url: this.icon, width: 32, height: 32 }
      } else {
        return {
          url: customUploadMarkerIcon(
            typeof this.color === 'string' ? this.color : DEFAULT_DATASET_COLOR,
            (this.label || this.name).substring(0, 2),
          ),
          width: 32,
          height: 32,
        }
      }
    }
  }

  getTag(): MapLayerTags | undefined {
    return this.tag
  }
}

export interface Tooltip {
  text: string
  property: string
  valueTransform?: (value: any, feature: google.maps.Data.Feature | Record<string, any>) => string
  rounded?: number

  /**
   * Additional CSS for the label element
   */
  labelStyle?: Partial<CSSStyleDeclaration>

  /**
   * Additional CSS for the value element
   */
  valueStyle?: Partial<CSSStyleDeclaration>
}

// Custom generic types for custom data
export class GenericEvForecastMetric extends MVTLayerConfiguration {
  metricType = 'predicted_range_of_electrical_vehicles' as EvForecastMetric
  selectedYear = '2026' as '2026' | '2028'

  filterUpdate() {
    this.coloring_property = this.baseline
  }

  updateMetrics(type: EvForecastMetric) {
    this.metricType = type
    this.filterUpdate()
  }

  updatePeriod(year: '2026' | '2028') {
    this.selectedYear = year
    this.filterUpdate()
  }

  get selectedType(): string {
    return this.metricType === 'predicted_range_of_electrical_vehicles' ? '' : 'scaled_'
  }

  get baseline(): string {
    return `baseline_${this.selectedType}${this.selectedYear}_Q3`
  }

  get optimistic(): string {
    return `optimistic_${this.selectedType}${this.selectedYear}_Q3`
  }

  get pessimistic(): string {
    return `pessimistic_${this.selectedType}${this.selectedYear}_Q3`
  }
}


export class GenericMacroPowerForecastMetric extends MVTLayerConfiguration {
  period = '2024'
  chargerCapacityOptions = 'optimistic'
  chargerDemandOption = 'optimistic'
}